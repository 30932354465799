<template>
    <div class="column gap--100" v-if="galleryThumbs">
        <div
            v-for="(item, index) in filteredImages"
            :key="'thumb-' + index"
            class="thumbnail"
            @click="openGallery(index)"
        >
            <img
                :src="item.src"
                :alt="`Thumbnail ${index + 1}`"
                class="gallery-thumbnail"
            />
        </div>
    </div>

    <div class="badge" v-if="galleryBtn">
        <button class="badge__inner"  @click="openGallery(0)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.9999 15.9957L17.3921 12.5416C16.4362 11.625 14.9251 11.6542 13.9993 12.5999L6.19826 20.6007M3 8.02347V15.9756C3 18.9218 4.84279 21 7.78737 21H16.2224C19.167 21 21 18.9218 21 15.9756V8.02347C21 5.0773 19.1573 3 16.2224 3H7.78737C4.84279 3 3 5.0773 3 8.02347ZM6.82522 9.17484C6.82522 10.1196 7.59054 10.8863 8.53575 10.8863C9.48001 10.8863 10.2463 10.1196 10.2463 9.17484C10.2463 8.23008 9.48001 7.46337 8.53575 7.46337C7.59054 7.46337 6.82522 8.23008 6.82522 9.17484Z" stroke="#191919" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>{{ btn }} ({{ gallery.length }})</span>
        </button>
    </div>

    <transition name="component-holder">
        <div
            v-if="showHolder && currentMediaObj"
            class="lightbox__holder"
            v-scroll-lock="showHolder"
        >
            <transition name="popup-base">
                <div
                    v-if="showComponent"
                    class="lightbox"
                    @mousedown="closingTransition"
                >
                    <img
                        :src="currentMediaObj.src"
                        :srcset="currentMediaObj.retina + ' 2x'"
                        :alt="currentMediaObj.title || 'Media'"
                        class="lightbox__media"
                    />

                    <p class="lightbox__text p3">{{ currentMediaObj.title }}</p>

                    <button
                        v-if="gallery.length > 1"
                        class="lightbox__arrow left"
                        @click="clickLeft()"
                        @mousedown.stop
                    >
                        <div class="button button--square">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16 20L8 12L16 4"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </button>

                    <button
                        v-if="gallery.length > 1"
                        class="lightbox__arrow right"
                        @click="clickRight()"
                        @mousedown.stop
                    >
                        <div class="button button--square">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 4L16 12L8 20"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </button>
                </div>
            </transition>
            <transition name="opacity-only">
                <button
                    class="lightbox__close button button--square"
                    v-if="showComponent"
                    @click="closingTransition"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M4 4L20 20M20 4L4 20"
                            stroke="#191919"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Gallery",
    props: {
        gallery: { type: Array, default: () => [] },
        btn: { type: String, default: "" },
        galleryBtn: { type: Boolean, default: false },
        galleryThumbs: { type: Boolean, default: false },
    },
    data() {
        return {
            currentMedia: 0,
            showHolder: false,
            showComponent: false,
        };
    },
    computed: {
        currentMediaObj() {
            return this.gallery[this.currentMedia];
        },
        filteredImages() {
            return this.gallery.filter(item => item.visible === true);
        }
    },
    watch: {
        showComponent(newVal, oldVal) {
            if (newVal) {
                document.body.classList.add('lightbox-opened');
            } else {
                setTimeout(() => {
                    document.body.classList.remove('lightbox-opened');
                }, 300);
            }
        }
    },
    methods: {
        openGallery(index = 0) {
            this.currentMedia = index;
            this.showHolder = true;
            this.showComponent = true;
        },
        closingTransition() {
            this.showComponent = false;
            setTimeout(() => {
                this.showHolder = false;
            }, 300); // Transition time
        },
        clickLeft(e) {
            this.currentMedia =
                this.currentMedia > 0
                    ? this.currentMedia - 1
                    : this.gallery.length - 1;
        },
        clickRight($event) {
            this.currentMedia =
                this.currentMedia < this.gallery.length - 1
                    ? this.currentMedia + 1
                    : 0;
        },
        onKeyUp($event) {
            switch ($event.key) {
                case "Escape":
                    this.closingTransition();
                    break;
                case "ArrowLeft":
                    this.clickLeft();
                    break;
                case "ArrowRight":
                    this.clickRight();
                    break;
            }
        },
        openGalleryHTML() {
            const openGalleryBtns = document.querySelectorAll(
                ".js-gallery-trigger"
            );

            // let preventDoubleClick = false;
            if (openGalleryBtns) {
                openGalleryBtns.forEach((btn) => {
                    btn.addEventListener("click", () => {
                        this.openGallery();
                    });
                });
            }
        },
    },
    mounted() {
        document.addEventListener("keyup", this.onKeyUp, false);
        this.openGalleryHTML();
    },
    beforeDestroy() {
        document.removeEventListener("keyup", this.onKeyUp, false);
        document.body.classList.remove('lightbox-opened');
    }
};
</script>

<style lang="scss" scoped>
.thumbnail {
    display: flex;
    width: 100%;
    cursor: pointer;

    & > img {
        width: 100%;
        border-radius: var(--radius-75);
    }
}

.thumbs {
    display: flex;
    gap: 8px;
    overflow: hidden;
    margin-top: 8px;

    &-item {
        max-width: 80px;


        img {
            border-radius: var(--radius-75);
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }
}

.lightbox {
    &__holder {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(5px);
        z-index: 99;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    width: 100%;
    height: 100%;

    &__text {
        display: block;
        margin-top: 8px;
    }

    &__media {
        display: flex;
        max-height: calc(100vh - 40px);
        max-width: calc(100vw - 224px);
        border-radius: var(--radius-75);

        img {
            object-fit: contain;
            border-radius: 6px;
        }

        &.hold-width {
            img {
                width: calc(100vw - 224px);
                max-height: calc(100vh - 40px);
            }
        }

        &.hold-height {
            img {
                height: calc(100vh - 40px);
                max-width: calc(100vw - 224px);
            }
        }
    }

    &__arrow {
        position: absolute;
        z-index: 100;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 112px;
        cursor: pointer;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        .button {
            height: 70px;
            width: 35px;

            @include breakpoint(t) {
                height: 92px;
                width: 42px;
            }
        }

        img {
            transition: transform 0.2s, filter 0.2s;
        }

        @media (hover: hover) {
            &:hover {
                img {
                    transform: scale(1.2);
                    filter: brightness(120%);
                }
            }
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        transition: transform 0.2s;
        cursor: pointer;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        z-index: 110;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

.popup {
    // No user select
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    width: 314px;
    max-width: calc(100% - 24px);
    display: grid;
    grid-template-rows: 60px minmax(0, 1fr);
    height: auto;
    max-height: 80vh;

    // @include breakpoint(t) {
    //     max-height: 80vh;

    //     @media screen and (max-height: 850px) {
    //         max-height: calc((var(--vh, 1vh) * 100) - 24px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    //     }
    // }

    &__holder {
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(5px);

        @include breakpoint(t) {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--media {
        width: auto;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        max-height: calc(
            (var(--vh, 1vh) * 100) - 24px - env(safe-area-inset-top) -
                env(safe-area-inset-bottom)
        );
    }

    &--desktop-media {
        width: auto;
    }
}

.component-holder {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.5s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }

    &-enter-to,
    &-leave {
        opacity: 1;
    }
}

// popup base grow
.popup-base {
    &-enter-active,
    &-leave-active {
        transition: all 0.5s;
    }

    &-enter,
    &-leave-to {
        transform: scale(0.9) translateZ(0);
    }

    &-enter-to,
    &-leave {
        transform: scale(1) translateZ(0);
    }
}
</style>
