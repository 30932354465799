export const initializeScrollers = function () {
    const scrollers = document.querySelectorAll(".scroller");
    scrollers.forEach((scroller) => {
        const scrollArea = scroller.querySelector(".scroll-area");
        const prevButton = scroller.querySelector(".prev");
        const nextButton = scroller.querySelector(".next");

        if (
            scroller.hasAttribute("data-initialized") &&
            scroller.getAttribute("data-initialized") === "true"
        ) {
            return;
        }

        const calculateItemWidth = () => {
            const firstItem = scrollArea.querySelector(":scope > *");
            const itemWidth = firstItem.offsetWidth;
            const scrollerStyle = window.getComputedStyle(scrollArea);
            const gap = parseInt(scrollerStyle.gap) || 0;
            return itemWidth + gap;
        };

        const updateButtonVisibility = () => {
            const scrollLeft = scrollArea.scrollLeft;
            const maxScrollLeft =
                scrollArea.scrollWidth - scrollArea.clientWidth;

            if (scrollLeft <= 0) {
                prevButton.classList.add("hide");
            } else {
                prevButton.classList.remove("hide");
            }

            if (scrollLeft >= maxScrollLeft - 10) {
                nextButton.classList.add("hide");
            } else {
                nextButton.classList.remove("hide");
            }
        };

        const scrollDuration = 600;

        const handleScroll = (amount) => {
            scrollArea.classList.remove("scroll-area--snap");

            scrollArea.scrollBy({
                top: 0,
                left: amount,
                behavior: "smooth",
            });

            setTimeout(() => {
                scrollArea.classList.add("scroll-area--snap");
                updateButtonVisibility();
            }, scrollDuration);
        };

        const getScrollAmount = (direction) => {
            const itemWidth = calculateItemWidth();
            const moveItems =
                parseInt(scrollArea.getAttribute("data-move-items")) || 1;
            return direction * itemWidth * moveItems;
        };

        prevButton?.addEventListener("click", () => {
            const scrollAmount = getScrollAmount(-1);
            handleScroll(scrollAmount);
        });

        nextButton?.addEventListener("click", () => {
            const scrollAmount = getScrollAmount(1);
            handleScroll(scrollAmount);
        });

        updateButtonVisibility();
        scroller.setAttribute("data-initialized", "true");
    });
};
