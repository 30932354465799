<template>
    <div class="rating" :class="{rated: resMessage && resMessage.length }">
        <div class="rating-message">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM16.7388 9.67396C17.111 9.26595 17.082 8.63345 16.674 8.26123C16.266 7.88902 15.6335 7.91803 15.2612 8.32604L10.5263 13.5162L8.73877 11.5568C8.36655 11.1488 7.73405 11.1198 7.32604 11.492C6.91803 11.8642 6.88902 12.4967 7.26123 12.9047L9.78755 15.674C9.97703 15.8817 10.2452 16 10.5263 16C10.8075 16 11.0756 15.8817 11.2651 15.674L16.7388 9.67396Z" fill="#19EB6D"/>
            </svg>
            <h3 class="rating__title rating__title--message">{{ resMessage }}</h3>
        </div>

        <div class="rating-buttons">
            <h3 class="rating__title rating__title--buttons">{{ translations.btnsTitle }}</h3>
            <div class="rating__buttons">
                <button :class="{ active: activeYes }" @click="addRate('yes')" class="rating__button">👍 {{ translations.btnYes }}</button>
                <button :class="{ active: activeNo }" @click="addRate('no')" class="rating__button">👎 {{ translations.btnNo }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { reactivity } from "@/store/reactivity";

export default {
    name: "Rating",
    props: {
        articleId: { type: String, default: '' },
        translations: { type: Object, default: () => { } }
    },
    data() {
        return {
            activeYes: null,
            activeNo: null,
            resMessage: "",
            clickCounter: 0,
            reactivity
        };
    },
    methods: {
        addRate(value) {
            this.clickCounter++;

            if(value == 'yes') {
                this.activeYes = true;
                this.activeNo = false;
            }
            if(value == 'no') {
                this.activeYes = false;
                this.activeNo = true;
            }
            if (this.articleId && value.length && this.clickCounter <= 1) {
                axios.post('/data/feedback', {
                    articleKey: Number(this.articleId),
                    vote: value
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.resMessage = response.data.message;
                    }
                })
            }
        },
    }
};
</script>

<style lang="scss" scoped>
 .rating {
     position: relative;
     overflow: hidden;

     &-buttons,
     &-message {
         display: flex;
         align-items: center;
         justify-content: center;

         @include breakpoint(t) {
             justify-content: flex-start;
         }
     }

     &-buttons {
         transition: opacity .3s;
         flex-direction: column;
         gap: var(--spacing-200);

         @include breakpoint(t) {
             flex-direction: row;
         }
     }

     h3.rating__title {
         font-weight: 400;
         font-size: 16px;
         margin-bottom: 0;
     }

     &__buttons {
         display: flex;
         align-items: center;
         justify-content: center;
         flex-flow: column;
         gap: var(--spacing-200);

         @include breakpoint(t) {
             justify-content: flex-start;
             flex-flow: row;
         }
     }

     &__button {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 200px;
         height: 50px;
         border-radius: 6px;
         background: var(--background-button-default);
         font-size: 16px;
         font-weight: 700;
         transition: all .3s;
         color: var(--text-primary);

         &:not(.active):hover {
             filter: brightness(90%);
         }

         &:active {
             transform: scale(0.97);
         }

         &.active {
             border-color: var(--background-button-brand-primary-default, var(--background-button-default));
             font-weight: 600;
         }

         &.disabled {
             cursor: not-allowed;
             opacity: .7;
         }
     }

     &-message {
         justify-content: flex-start;
         gap: var(--spacing-150);
         font-style: italic;
         opacity: 0;
         transform: translateX(200px);
         transition: opacity .3s .2s, transform .3s .2s;
         position: absolute;
         left: 0;
         height: 100%;
         width: 100%;
         z-index: -1;
     }

     &.rated {
         .rating {
             &-buttons {
                 opacity: 0;
                 z-index: -1;
             }

             &-message {
                 opacity: 1;
                 transform: translateX(0);
                 z-index: unset;
             }
         }
     }
}
</style>
