<template>
	<div class="dropdown-holder" :class="[modifiers.map((c) => 'dropdown-holder--' + c)]" @click.stop="">
		<ul class="dropdown measuring" :class="[modifiers.map((c) => 'dropdown--' + c)]">
			<li v-for="x in items" :key="'selecteditem' + x.id" class="dropdown__item">
				<div class="dropdown__item-inner">
					<img v-if="!emoji" :src="x.icon" :alt="x.text" class="icon icon--image" />
					<div v-else class="icon icon--emoji">
						<span>{{ x.icon }}</span>
					</div>
					<span v-if="reservedSpace" class="text">{{ x.text }}</span>
				</div>
			</li>
		</ul>

		<ul
			class="dropdown data"
			:class="[modifiers.map((c) => 'dropdown--' + c), { 'is-open': open }]"
			:style="{ maxHeight }"
		>
			<li
				v-for="(x, i) in items"
				:key="i"
				@click="dropDown(x)"
				class="dropdown__item"
				:class="{ selected: x.selected }"
			>
				<div class="dropdown__item-inner p p2">
					<img v-if="!emoji" :src="x.icon" :alt="x.text" class="icon icon--image" />
					<div v-else class="icon icon--emoji">
						<span>{{ x.icon }}</span>
					</div>
					<span class="text">{{ x.text }}</span>
				</div>
			</li>
		</ul>

        <div class="dropdown__drop-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.19033 4.86694C1.93273 5.13001 1.93717 5.5521 2.20025 5.80969L7.64706 11.143C7.90623 11.3968 8.32072 11.3968 8.57989 11.143L13.7998 6.03192C14.0628 5.77432 14.0673 5.35224 13.8097 5.08916C13.5521 4.82608 13.13 4.82164 12.8669 5.07924L8.11348 9.73365L3.13308 4.85701C2.87001 4.59942 2.44792 4.60386 2.19033 4.86694Z" fill="#0D0D0D"/>
            </svg>
        </div>

	</div>
</template>

<script>

export default {
	name: 'LangDropdown',
	props: {
		items: { type: Array, default: () => [] },
		emoji: { type: Boolean, default: false },
		modifiers: { type: Array, default: () => [] },
		alwaysOpen: { type: Boolean, default: false },
		reservedSpace: { type: Boolean, default: true },
	},
	data() {
		return {
			open: false,
		};
	},
	computed: {
		isMobile() {
			return this.modifiers.includes('mobile');
		},
		maxHeight() {
			return this.open ? this.items.length * (42 + 4) + 4 + 'px' : '50px';
		},
	},
	methods: {
		selectItem(item) {
			this.forceClose();
			if (!item.selected) window.location.href = item.url;
		},
		closeListener() {
			document.addEventListener('click', this.forceClose, { once: true });
			document.addEventListener('scroll', this.forceClose, { once: true });
		},
		forceClose() {
			this.open = false;
			if (this.alwaysOpen) {
				setTimeout(() => {
					this.$emit('close');
				}, 400);
			}
		},
		dropDown(item) {
			if (!this.open) {
				this.open = true;
				this.closeListener();
			} else {
				this.selectItem(item);
			}
		},
	},
	mounted() {
		if (this.alwaysOpen) {
			setTimeout(() => {
				this.dropDown();
			}, 10);
		}
	},
};
</script>

<style lang="scss" scoped>
li {
	list-style: none;
}

.dropdown {
	&-holder {
		position: relative;
		z-index: 1;
		width: max-content;
	}

	display: flex;
	flex-direction: column;
	padding: 4px 4px 0;
	min-width: 0;
	text-align: left;
	color: var(--text-primary);
	max-height: 50px;
    border-radius: var(--radius-75);
    border: 1px solid transparent;
	overflow: hidden;
	transition: background 0.2s 0.3s, max-height 0.3s, box-shadow 0.2s 0.3s;

    &__drop-icon {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        z-index: 10;
        pointer-events: none;

        path {
            fill: var(--text-primary);
        }
    }

	&.measuring {
		opacity: 0;
		pointer-events: none;
	}

	&.data {
		position: absolute;
		top: 0;
		right: 0;
	}

	&.is-open {
		max-height: 200px;
		background: var(--background-primary);
        border-color: var(--border-secondary);
		z-index: 1;
		transition: background 0.2s, box-shadow 0.2s, max-height 0.3s 0.1s;

		.dropdown__item.selected {
			.dropdown__item-inner {
				background: var(--background-secondary);
			}
		}
	}

	&__item {
		flex-shrink: 0;
		margin-bottom: 4px;

		&.selected {
			order: -1;
		}

		@media (hover: hover) {
			&:hover .dropdown__item-inner {
				background: var(--background-secondary);
			}
		}

		.icon {
			margin-right: var(--spacing-75);

			&--image {
				width: 24px;
                height: 16px;
			}

			&--emoji {
				font-size: 20px;
				margin-right: 8px;
			}
		}

		span {
			margin-right: auto;
		}

		img:not(.icon) {
			margin-left: 12px;
		}

		&-inner {
			display: flex;
			align-items: center;
			height: 42px;
			padding: 0 34px 0 var(--spacing-150);
			border-radius: 6px;
			transition: background-color 0.2s linear;
			cursor: pointer;
		}
	}

	&--no-text {
        .dropdown__item {
            &-inner {
                padding-right: 28px;
                transition: padding .2s;
            }
        }

        &.is-open {
            .dropdown__item {
                &-inner {
                    padding-right: 34px;
                }
            }
        }

		&:not(.measuring) {
			.dropdown__item {
				.text {
					opacity: 0;
					max-width: 0;
					transition: max-width 0.3s, opacity 0.2s;
				}
			}

			&.is-open {
				.dropdown__item {
					.text {
						opacity: 1;
						max-width: 200px;
						transition: max-width 0.3s, opacity 0.2s 0.1s;
					}
				}
			}
		}
	}
}
</style>
