window._ = require("lodash");
const axios = require("axios").default;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

try {
    //
} catch (e) {
    console.error(e);
}

let token = document.head.querySelector('meta[name="csrf-token"]');
const appLang = document.getElementsByTagName("html")[0].getAttribute("lang");

if (token) {
    axios.defaults.headers.common = {
        "X-CSRF-TOKEN": token.content,
        "X-Requested-With": "XMLHttpRequest",
        "App-Locale": appLang.length ? appLang : "",
        "App-Device": window.innerWidth < 768 ? "mobile" : "desktop",
    };
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import { createApp } from "vue";
import { Vue3Mq, MqResponsive } from "vue3-mq";
import VueSocialSharing from "vue-social-sharing";
import V3ScrollLock from "v3-scroll-lock";
import Emitter from "tiny-emitter";
// Settings components
import AppSettings from "@/AppSettings";
// custom components
import LangDropdown from "@/components/LangDropdown";
import Search from "@/components/Search";
import Hamburger from "@/components/Hamburger";
import HamburgerMenu from "@/components/HamburgerMenu";
import Feedback from "@/components/Feedback";
import ActionLike from "@/components/ActionLike";
import ActionShare from "@/components/ActionShare";
import LoadMoreButton from "@/components/LoadMoreButton";
import LoadMoreButtonList from "@/components/LoadMoreButtonList";
import ThemeSwitch from "@/components/ThemeSwitch";
// global components
import cSlideDown from "@/components/cSlideDown";
import cInfoStripe from "@/components/cInfoStripe";
import cGallery from "@/components/cGallery";
// crema components
import SlideUp from "@/components/crema/SlideUp";
import SearchForm from "@/components/crema/SearchForm";
import SearchFormButton from "@/components/crema/SearchFormButton";
import SiteGallery from "@/components/crema/SiteGallery";
import LoadMoreSites from "@/components/crema/LoadMoreSites";

import { reactivity } from "@/store/reactivity";

const app = createApp({
    data() {
        return {
            reactivity,
        };
    },
    methods: {
        setColorScheme(themeName) {
            axios.put("/api/color-theme", {
                theme: themeName,
            });
        },
    },
    mounted() {
        if (reactivity.themeCookie == "none") {
            // fv
            let domain = window.location.host.split(".");
            if (domain && domain[1] == "freevideo") {
                this.setColorScheme("dark");
                document.body.setAttribute("data-theme", "dark");
            } else {
                // default
                if (
                    window.matchMedia &&
                    window.matchMedia("(prefers-color-scheme: dark)") &&
                    window.matchMedia("(prefers-color-scheme: dark)").matches
                ) {
                    document.body.setAttribute("data-theme", "dark");
                    this.setColorScheme("dark");
                    this.on = true;
                } else {
                    document.body.setAttribute("data-theme", "light");
                    this.setColorScheme("light");
                    this.on = false;
                }
            }
        }
    },
});
// global event bus
const emitter = new Emitter();
// 3rd part components
app.use(Vue3Mq, {
    breakpoints: {
        phone: 0,
        tablet: 600,
        desktop: 1040,
    },
});
app.use(VueSocialSharing);
app.use(V3ScrollLock, { allowTouchMove: true });
// components
app.component("mq-responsive", MqResponsive);
app.component("app-settings", AppSettings);
app.component("lang-dropdown", LangDropdown);
app.component("search", Search);
app.component("hamburger", Hamburger);
app.component("hamburger-menu", HamburgerMenu);
app.component("feedback", Feedback);
app.component("action-like", ActionLike);
app.component("action-share", ActionShare);
app.component("load-more-button", LoadMoreButton);
app.component("load-more-button-list", LoadMoreButtonList);
app.component("theme-switch", ThemeSwitch);
// crema components
app.component("slide-up", SlideUp);
app.component("search-form", SearchForm);
app.component("search-button", SearchFormButton);
app.component("site-gallery", SiteGallery);
app.component("load-more-sites", LoadMoreSites);
// global components c-rema
app.component("c-slide-down", cSlideDown);
app.component("c-gallery", cGallery);
app.component("c-info-stripe", cInfoStripe);
// mounting
app.provide("emitter", emitter);
app.mount("#app");

// TODO: rewrite pub functions
require("./helpers");

// site item - image hover effect
import { initializePreview } from "./hooks/sitePreview";
import { initializeScrollers } from "./hooks/scroller";
document.addEventListener("DOMContentLoaded", () => {
    // site preview
    initializePreview();
    // scrollers
    initializeScrollers();
});
