<template></template>
<script>
import { reactivity } from "@/store/reactivity";
import { fingerprint } from "@/hooks/browserFingerprint";

export default {
    name: "App-Settings",
    props: {
        dataType: { type: String, default: () => "" },
        dataDomain: { type: String, default: () => "" },
        fileId: { type: String, default: () => "" },
        translations: { type: Object, default: () => {} },
        langDropdown: { type: Array, default: () => [] },
        themeCookie: { type: String, default: () => "" },
        settingsData: { type: Object, default: () => {} },
    },
    data() {
        return {
            activeYes: null,
            activeNo: null,
            resMessage: "",
            fingerprint: "",
            clickCounter: 0,
            reactivity,
        };
    },
    computed: {
        browserTimeZone() {
            try {
                return Intl.DateTimeFormat().resolvedOptions().timeZone;
            } catch (error) {
                console.error("Error fetching browser time zone:", error);
                return "UTC";
            }
        },
    },
    mounted() {
        reactivity.translations = this.translations;
        reactivity.langDropdownData = this.langDropdown;
        reactivity.themeCookie = this.themeCookie;
        reactivity.type = this.dataType;
        reactivity.domain = this.dataDomain;
        reactivity.fileId = this.fileId;
        reactivity.settings = this.settingsData;
        fingerprint()
            .then((hash) => {
                reactivity.fingerPrint = hash;
            })
            .catch(() => {
                reactivity.fingerPrint = "unknown";
            });
        reactivity.timeZone = this.browserTimeZone;
    },
};
</script>
