<template>
    <div class="search-wrapper">
        <div class="search">
            <button
                v-if="showArrowBtn"
                @click="closeSearch"
                class="button button--square button--curved"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M16 20L8 12L16 4"
                        stroke="#191919"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <label class="search-input">
                <div class="search-input__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M16 16L20 20M18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z"
                            stroke="#191919"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>

                <input
                    type="text"
                    name="search"
                    autocomplete="off"
                    :placeholder="
                        mq.current !== 'phone'
                            ? reactivity.translations.searchPlaceholder
                            : reactivity.translations.searchPlaceholderMobile
                    "
                    v-model="query"
                    @keyup="startSearch"
                    @keyup.up.prevent=""
                    @keyup.down.prevent=""
                    @keydown.esc="clearFld"
                    @keyup.enter="submitForm"
                    @click="onSearchFocus($event)"
                    @blur="isSearchFocused = false"
                    ref="input"
                    autofocus
                />
                <div class="search-input__status">
                    <div
                        v-if="!isLoading && query.length > 0"
                        class="status status--cross"
                        @click.stop="clearFld"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                        >
                            <path
                                d="M8,8,0,16,8,8,0,0,8,8l8-8L8,8l8,8Z"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#7e7e7e"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <div v-if="isLoading" class="status status--loader">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.001"
                            height="17.001"
                            viewBox="0 0 17.001 17.001"
                        >
                            <g transform="translate(-334.5 -102.5)">
                                <path
                                    d="M8,16A8,8,0,0,1,4.886.629a8,8,0,0,1,6.228,14.743A7.95,7.95,0,0,1,8,16ZM8,1.25A6.752,6.752,0,0,0,5.373,14.22a6.752,6.752,0,0,0,5.255-12.44A6.708,6.708,0,0,0,8,1.25Z"
                                    transform="translate(335 103)"
                                    fill="#c3c3c3"
                                    stroke="#c3c3c3"
                                />
                                <path
                                    d="M6.423,11.676a.621.621,0,0,1,.1-.333h0a6.815,6.815,0,0,0,.353-.714,6.774,6.774,0,0,0,0-5.255A6.726,6.726,0,0,0,5.424,3.227,6.729,6.729,0,0,0,.65,1.25H.625A.625.625,0,0,1,.625,0H.65A7.952,7.952,0,0,1,3.764.629,7.976,7.976,0,0,1,6.307,2.344,7.975,7.975,0,0,1,8.022,4.887a8.023,8.023,0,0,1,0,6.228,8.032,8.032,0,0,1-.4.815h0a.625.625,0,0,1-1.2-.253Z"
                                    transform="translate(342.351 103)"
                                    fill="#707070"
                                    stroke="#707070"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </label>
            <button
                v-if="mq.current !== 'phone'"
                @click="submitForm"
                class="button button--brand--primary"
            >
                {{ reactivity.translations.searchBtn }}
            </button>
        </div>

        <div v-if="isDefault" class="column gap--300 gap--t--400">
            <div class="row row--wrap gap--100">
                <a
                    v-for="(item, i) in defaultSearches"
                    :href="
                        reactivity.settings.searchActionUrl +
                        '?q=' +
                        item.query.replace(/ /g, '+')
                    "
                    :key="'site-' + i"
                    class="button button--small button--curved"
                >
                    <img
                        src="@@@/pornsitesall/general/search.svg"
                        alt="search"
                    />
                    {{ item.query }}
                </a>
            </div>
            <a
                v-if="reactivity.settings.allTagsUrl"
                :href="reactivity.settings.allTagsUrl"
                class="button button--outline button--width button--rounded mlr-a"
            >
                <span>{{ reactivity.translations.showAllTagsBtn }}</span>
            </a>
        </div>

        <div
            v-else-if="queryDone && !isLoading"
            class="results column gap--300 gap--t--400"
        >
            <div
                v-if="
                    hasResults &&
                    results.categories.length > 0 &&
                    results.tags.length > 0
                "
                class="column gap--300 gap--t--400"
            >
                <h3>
                    {{ reactivity.translations.psaTagCategory }}
                </h3>
                <ul class="row gap--100 row--wrap">
                    <li
                        tabindex="0"
                        v-for="(item, i) in results.categories"
                        :key="'cat-' + i"
                    >
                        <a
                            :href="item.url"
                            class="button button--small button--curved"
                            @click="$event.stopPropagation()"
                        >
                            <img
                                v-if="item.image"
                                :src="item.image"
                                :alt="item.name"
                                class="results__item__icon results__item__icon--category"
                            />
                            {{ item.name }}
                        </a>
                    </li>
                    <li
                        tabindex="0"
                        v-for="(item, i) in results.tags"
                        :key="'tag-' + i"
                    >
                        <a
                            :href="item.url"
                            @click="$event.stopPropagation()"
                            class="button button--small button--curved"
                        >
                            <img
                                src="@@@/pornsitesall/general/hashtag.svg"
                                alt="search"
                            />
                            {{ item.name }}
                        </a>
                    </li>
                </ul>
            </div>

            <div
                v-if="hasResults && results.items.length > 0"
                class="column gap--300 gap--t--400"
            >
                <h3>
                    {{ reactivity.translations.psaSites }}
                </h3>
                <ul class="column gap--50">
                    <li
                        :tabindex="0"
                        v-for="(item, i) in results.items"
                        :key="i"
                        @keyup.up="arrowUp(item, i)"
                        @keyup.down="arrowDown(item, i)"
                        @keyup.enter="goToDestination(item, i)"
                        @mouseenter="mouseEnter(item, i)"
                        :ref="'item-' + i"
                        class="results__list__item"
                    >
                        <a
                            :href="item.url"
                            class="results__item row gap--150"
                            @click="$event.stopPropagation()"
                        >
                            <img
                                v-if="item.image"
                                :src="item.image"
                                :srcset="item.imageRetina + ' 2x'"
                                :alt="item.name"
                                class="results__item__icon"
                            />
                            <div class="column gap--0 shrink">
                                <div class="row gap--50">
                                    <h4>{{ item.name }}</h4>
                                    <!-- TODO: Add raing -->
                                    <!-- <div class="row">
                                        <div
                                            class="label"
                                            style="
                                                --custom-color: var(
                                                    --state-success-hover
                                                );
                                            "
                                        >
                                            9.6
                                        </div>
                                        <div
                                            class="label"
                                            style="
                                                --custom-color: var(
                                                    --state-focus-default
                                                );
                                            "
                                        >
                                            Trending
                                        </div>
                                    </div> -->
                                </div>
                                <p class="p4 description">
                                    {{ item.description }}
                                </p>
                            </div>
                            <button
                                class="button button--small button--brand--secondary ml-a"
                            >
                                {{ reactivity.translations.psaSitePreviewBtn }}
                            </button>
                        </a>
                    </li>
                </ul>
            </div>

            <div
                v-if="!hasResults"
                class="column gap--300 gap--t--400 column--center"
            >
                <div class="column gap--100 no-results__message">
                    <h3>
                        {{ reactivity.translations.psaSearchResultsTitle }}
                        “{{ query }}”
                    </h3>
                    <p class="p2">
                        {{ reactivity.translations.psaSearchResultsText }}
                    </p>
                </div>
                <a
                    :href="reactivity.settings.discoverUrl"
                    class="button button--outline button--rounded"
                >
                    <span>{{ reactivity.translations.psaSiteResultsBtn }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import { reactivity } from "@/store/reactivity";

export default {
    name: "SearchSlideDown",
    props: {
        modifiers: { type: Array, default: () => [] },
        translations: { type: Array, default: () => [] },
        defaultSearches: { type: Array, default: () => [] },
        showArrowBtn: { type: Boolean, default: true },
    },
    data() {
        return {
            isDefault: true,
            query: "",
            queryDone: false,
            isLoading: false,
            isSearchFocused: false,
            results: [],
            focusedItem: null,
            reactivity,
        };
    },
    inject: ["mq", "emitter"],
    computed: {
        responsiveButton() {
            return (
                this.modifiers.includes("landing") &&
                this.mq.current !== "phone"
            );
        },
        searchActionUrl() {
            return this.reactivity.settings.hasOwnProperty("searchActionUrl")
                ? this.reactivity.settings.searchActionUrl + "?q=" + this.query
                : "";
        },
        searchesUrl(query) {
            return;
        },
        hasResults() {
            return !!(
                (this.results?.categories && this.results?.categories.length) ||
                (this.results?.items && this.results?.items.length)
            );
        },
        hasQueries() {
            return !!(this.defaultSearches && this.defaultSearches.length > 0);
        },
    },
    methods: {
        getResults() {
            this.isLoading = true;
            this.queryDone = false;

            const appLang =
                document.getElementsByTagName("html")[0].getAttribute("lang") ||
                "en";

            axios
                .get(`/api/search?q=${encodeURI(this.query)}`, {
                    headers: {
                        Language: appLang,
                        "App-Zone":
                            Intl.DateTimeFormat().resolvedOptions().timeZone ||
                            "",
                    },
                })
                .then((response) => {
                    this.results = null;
                    if (response.status == 200) {
                        this.results = response.data.results;
                        this.queryDone = true;
                        this.isLoading = false;
                        if (response.data.results.length < 0) {
                            this.isDefault = false;
                        }
                    }
                });
        },
        clearFld() {
            this.query = "";
            this.results = "";
            this.focusedItem = null;
            this.isSearchFocused = false;
            this.isDefault = true;
        },
        closeSearch() {
            this.clearFld();
            this.reactivity.cSlideDown = false;
            this.reactivity.cSlideDownSearchBtn = true;
        },
        onSearchFocus($event) {
            this.$refs.input.focus();
            this.isSearchFocused = true;
            $event.stopPropagation();
        },
        search: _.debounce(function () {
            if (this.query && this.query.length) {
                this.isDefault = false;

                this.getResults();
            }
        }, 500),
        submitForm() {
            if (this.query && this.query.length)
                window.location = this.searchActionUrl;
        },
        arrowUp(item, i) {
            let ref = this.$refs["item-" + (i - 1)];
            // if (i - 1) this.$refs.input.focus(); focus input
            if (i >= 0 && ref) ref[0].focus();
        },
        arrowDown(item, i) {
            if (this.hasResults) {
                let ref = this.$refs["item-" + (i + 1)];
                if (i < this.results?.items?.length && ref) ref[0].focus();
                else this.$refs["item-0"][0].focus();
            }
        },
        startSearch(e) {
            if (e.key == "ArrowUp") return;
            if (e.key == "ArrowDown" && this.hasResults) {
                this.$refs["item-" + 0][0].focus();
                return;
            }
            this.search();
        },
        mouseEnter(item, i) {
            let ref = this.$refs["item-" + i];
            if (i < this.results.length && ref) ref[0].focus();
        },
        goToDestination(item, i) {
            let parsedItem = JSON.parse(JSON.stringify(item));
            window.location.href = parsedItem.url;
        },
    },
    watch: {
        isSearchFocused: function (n) {
            if (n) document.body.classList.add("is-focused");
            if (!n) document.body.classList.remove("is-focused");
        },
        isSearchVisible(newVal) {
            if (newVal) {
                console.log(this.$refs.input);
                this.$nextTick(() => {
                    if (this.$refs.input) {
                        this.$refs.input.focus();
                    }
                });
            }
        },
    },
    mounted() {
        // new code
        const inputSearchElements =
            document.querySelectorAll(".js-show-search");

        inputSearchElements.forEach((inputSearch) => {
            inputSearch.addEventListener("click", (e) => {
                this.reactivity.cSlideDown = true;
            });
        });
        this.emitter.on("searchToggle", () => {
            this.$nextTick(() => {
                if (this.$refs.input) this.$refs.input.focus();
            });
        });
    },
};
</script>
<style lang="scss" scoped>
.search-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-300);
    position: relative;
    max-width: 100%;
}
.results {
    width: 100%;

    &__list {
        &__item {
            margin: 0 calc(var(--spacing-150) * -1);
            transition: background-color 0.24s;
            border-radius: var(--radius-75);

            &:hover,
            &:focus {
                background: var(--background-tertiary);
            }
        }
    }

    &__item {
        display: flex;
        padding: var(--spacing-100) var(--spacing-150);

        &__icon {
            width: 44px;
            height: 44px;
            display: block;
            background: var(--background-tertiary);
            border-radius: var(--radius-75);

            &--category {
                height: 17px;
                width: 17px; // TODO: PA / PT - change to auto when category icon works
            }
        }

        .column {
            overflow: hidden;
        }

        .description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.no-results__message {
    min-height: 128px;
    justify-content: center;
    text-align: center;
}
</style>
