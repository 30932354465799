<template>
    <div
        v-for="html in responseHTML"
        :key="html.id"
        v-html="html.content.data"
        :class="layout"
    ></div>

    <div class="load-more-button" v-if="showBtn">
        <button class="button button--width mlr-a" @click="loadMore">
            <span v-if="isLoading" class="loader">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 17.001 17.001"
                >
                    <g transform="translate(-334.5 -102.5)">
                        <path
                            d="M8,16A8,8,0,0,1,4.886.629a8,8,0,0,1,6.228,14.743A7.95,7.95,0,0,1,8,16ZM8,1.25A6.752,6.752,0,0,0,5.373,14.22a6.752,6.752,0,0,0,5.255-12.44A6.708,6.708,0,0,0,8,1.25Z"
                            transform="translate(335 103)"
                            fill="#c3c3c3"
                            stroke="#c3c3c3"
                        />
                        <path
                            d="M6.423,11.676a.621.621,0,0,1,.1-.333h0a6.815,6.815,0,0,0,.353-.714,6.774,6.774,0,0,0,0-5.255A6.726,6.726,0,0,0,5.424,3.227,6.729,6.729,0,0,0,.65,1.25H.625A.625.625,0,0,1,.625,0H.65A7.952,7.952,0,0,1,3.764.629,7.976,7.976,0,0,1,6.307,2.344,7.975,7.975,0,0,1,8.022,4.887a8.023,8.023,0,0,1,0,6.228,8.032,8.032,0,0,1-.4.815h0a.625.625,0,0,1-1.2-.253Z"
                            transform="translate(342.351 103)"
                            fill="#707070"
                            stroke="#707070"
                        />
                    </g>
                </svg>
            </span>
            <span v-else>{{ translations.btn }}</span>
        </button>
    </div>
</template>

<script>
import axios from "axios";
import { initializePreview } from "@/hooks/sitePreview.js";
import { initializeScrollers } from "@/hooks/scroller.js";

export default {
    name: "LoadMoreSites",
    inject: ["emitter", "mq"],
    props: {
        endpoint: { type: String, default: "" },
        layout: { type: String, default: "" },
        translations: { type: Object, default: () => {} },
        scrollOffset: { type: Number, default: 1200 }, // Offset from the bottom in pixels
    },
    data() {
        return {
            loadPage: 1,
            showBtn: true,
            responseHTML: [],
            isLoading: false,
            canLoad: false,
        };
    },
    computed: {
        query() {
            let params = new URLSearchParams(window.location.search);
            return params.get("q");
        },
    },
    methods: {
        async getArticles() {
            if (this.isLoading) return; // Prevent multiple calls
            this.isLoading = true;
            this.loadPage++;
            const url = `${this.endpoint}?page=${this.loadPage}&q=${this.query}`;
            try {
                const response = await axios.get(url);
                this.responseHTML.push({
                    id: this.loadPage,
                    content: response.data,
                });
                this.isLoading = false;
                if (response.data === "" || response.data.status != 1) {
                    this.canLoad = false; // No more articles
                    this.showBtn = false;
                }
                this.$nextTick(() => {
                    // nextTick to new rendered elements
                    this.emitter.emit("fetchingData");
                    initializeScrollers();
                    initializePreview();
                });
            } catch (error) {
                console.error("Error loading more articles:", error);
                this.isLoading = false;
            }
        },
        loadMore() {
            setTimeout(function () {
                document.body.classList.add("hide-pagination");
            }, 3000);
            this.getArticles();
            this.canLoad = true;
            // init scroller
        },
        checkScroll() {
            const nearBottom =
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight - this.scrollOffset;
            if (nearBottom && this.canLoad) {
                this.getArticles();
            }
        },
    },
    mounted() {
        window.addEventListener("scroll", this.checkScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.checkScroll);
    },
};
</script>

<style lang="scss" scoped>
.load-more-button {
    display: flex;
    height: var(--default-height);
}
.loader {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 var(--spacing-200);
    animation: spin 750ms infinite;
}
</style>
