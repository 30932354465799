<template>
    <div
        class="stripe"
        :class="[modifiers.map((c) => 'stripe--' + c)]"
        v-if="opened"
    >
        <div class="stripe--1"></div>
        <div class="stripe--2 row gap--200">
            <p class="p p3">{{ text }}</p>
            <a :href="target" target="_blank" class="button button--small">{{
                btn
            }}</a>
        </div>
        <div class="stripe--3">
            <button
                class="button button--small button--transparent-only"
                @click="closeSlideUp"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M4 4L20 20M20 4L4 20"
                        stroke="#191919"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Info row",
    props: {
        modifiers: { type: Array, default: () => [] },
        target: { type: String, default: "" },
        text: { type: String, default: "" },
        btn: { type: String, default: "" },
    },
    data() {
        return {
            opened: true,
        };
    },
    methods: {
        closeSlideUp() {
            this.opened = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.stripe {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 var(--spacing-100);
    background: var(--background-secondary, var(--background-button-default));
    display: flex;
    height: 56px;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    $s-value: map-get($breakpoints, "s");

    &--2 {
        @media (max-width: $s-value) {
            .p {
                display: none;
            }
        }
    }
}
</style>
