import axios from "axios";

// **************** BREAKPOINTS
// Following setup from SCSS
const breakpoints = {
    s: 414,
    t: 600,
    d: 1040,
    m: 1200,
    f: 1600,
};

// **************** COPY TO CLIPBOARD
function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function () {
            console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
            console.error("Async: Could not copy text: ", err);
        }
    );
}

const copyBtn = document.querySelector(".js-copy-bob-btn");
if (copyBtn) {
    copyBtn.addEventListener("click", function (event) {
        copyTextToClipboard(copyBtn.getAttribute("data-copy-url"));
    });
}

// **************** BUTTON LINK - SCROLL TO TOP
const btnScrollToTop = document.querySelector(".js-scroll-top");

if (btnScrollToTop) {
    btnScrollToTop.onclick = function () {
        window.scroll({ top: 0, behavior: "smooth" });
    };
}
// **************** ENHANCED LINK-NAV
const nav = document.querySelector(".link-nav--enhanced");
const navBlock = document.querySelector(".link-nav--enhanced .link-nav-sub");

if (nav) {
    nav.addEventListener("click", function () {
        nav.classList.toggle("opened");
        navBlock.classList.toggle("opened");
    });
}

// **************** SEARCH FORM OR SEARCH BUTTON
const subSectionSearch = document.querySelector(".sub-section--search");
const searchButton = document.querySelector(".js-search-button");

if (subSectionSearch && searchButton) {
    document.addEventListener("DOMContentLoaded", function () {
        let observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    searchButton.classList.remove("scrolled");
                } else {
                    searchButton.classList.add("scrolled");
                }
            });
        });

        observer.observe(subSectionSearch);

        window.addEventListener("scroll", function () {
            observer.observe(subSectionSearch);
        });
    });
}
// **************** SMOOTH scroll
document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll(".js-smooth-scroll").forEach((button) => {
        button.addEventListener("click", function () {
            const targetId = this.getAttribute("data-scroll");
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop - 20,
                    behavior: "smooth",
                });
            }
        });
    });
});

document.addEventListener("DOMContentLoaded", function () {
    // **************** SHOW all
    const showAllButton = document.querySelector(".js-showall-btn");
    const hiddenButtons = document.querySelectorAll(".default-hidden-m");

    if (showAllButton && hiddenButtons) {
        showAllButton.addEventListener("click", function () {
            hiddenButtons.forEach(function (button) {
                button.classList.toggle("visible");
            });

            this.classList.toggle("visible");
        });
    }

    // **************** READ MORE
    function handleReadMoreClick() {
        this.classList.remove(
            "line-clamp",
            "line-clamp--read-more",
            "line-clamp--read-more--em"
        );
        this.removeEventListener("click", handleReadMoreClick);
    }

    const readMoreAll = document.querySelectorAll(".line-clamp--read-more");

    readMoreAll.forEach(function (readMoreElement) {
        readMoreElement.addEventListener("click", handleReadMoreClick);
    });
    // **************** SECTIONS COLLAPSING
    const collapseTriggers = document.querySelectorAll(".js-collapse-trigger");

    collapseTriggers.forEach(function (trigger) {
        trigger.addEventListener("click", function () {
            let section = this.parentElement;
            section.classList.toggle("closed");

            if (section.classList.contains("is-sticky")) {
                section.classList.remove("is-sticky");
            }
        });
    });
});
