<template>
    <button
        :class="{ 'button--brand--primary': isActive }"
        @click="setLike()"
        class="button button--square button--rounded"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M8.20325 9.8996C9.48838 8.16322 10.7632 6.41791 12.0589 4.69012C12.4694 4.14271 13.1882 3.86699 13.8334 4.06302C15.2726 4.50024 14.9052 7.16758 14.5288 8.58221C14.2497 9.63123 14.5891 9.9951 15.4063 9.9951C17.1933 9.9951 18.0424 9.9951 18.6507 10.3771C19.2071 10.7265 19.6156 11.2838 19.7935 11.9361C20.1645 13.2963 20.0367 15.8872 19.4009 17.6931C18.9715 18.9131 17.76 19.7522 16.7106 19.8739C15.2937 20.0382 13.3158 19.9789 11.8225 19.8922C10.4503 19.8126 9.17238 19.1746 8.20325 18.1563M6.10187 8.89504C5.59489 8.89504 5.3414 8.89503 5.13409 8.95616C4.61288 9.10982 4.20553 9.53551 4.05849 10.0802C4 10.2968 4 10.5617 4 11.0914V17.8036C4 18.3333 4 18.5981 4.05849 18.8148C4.20553 19.3595 4.61288 19.7851 5.13409 19.9389C5.3414 19.9999 5.59489 20 6.10187 20C6.60884 20 6.86233 19.9999 7.06965 19.9389C7.59085 19.7851 7.99819 19.3595 8.14524 18.8148C8.20373 18.5981 8.20373 18.3333 8.20373 17.8036C8.20373 15.595 8.20373 13.3 8.20373 11.0914C8.20373 10.5617 8.20373 10.2968 8.14524 10.0802C7.99819 9.53551 7.59085 9.10982 7.06965 8.95616C6.86233 8.89503 6.60884 8.89504 6.10187 8.89504Z"
                stroke="#191919"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </button>
</template>

<script>
import axios from "axios";
import { reactivity } from "@/store/reactivity";

export default {
    name: "ActionLike",
    props: {
        actionEventName: { type: String, default: "" },
        itemId: { type: Number, default: null },
        isLiked: { type: Boolean, default: false },
        translations: { type: Object, default: () => {} },
    },
    data() {
        return {
            isActive: false,
            resMessage: "",
            clickCounter: 0,
            reactivity,
        };
    },
    methods: {
        setLike() {
            this.isActive = !this.isActive;
            axios
                .put("/data/collect", {
                    actionEventName: this.actionEventName,
                    domain: this.reactivity.domain,
                    itemId: Number(this.itemId),
                    value: this.isActive,
                    fp: reactivity?.fingerPrint,
                    btz: reactivity?.timeZone,
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.resMessage = response.data.message;
                    }
                });
        },
    },
    mounted() {
        this.isActive = this.isLiked;
    },
};
</script>

<style lang="scss" scoped></style>
