<template>
    <div v-if="showBtn" class="load-more-button">
        <button class="button button--width mlr-a" @click="loadMoreAricles">
            {{ translations.btn }}
        </button>
    </div>
</template>

<script>
import axios from "axios";
import { reactivity } from "@/store/reactivity";

export default {
    name: "LoadMoreButton",
    props: {
        endpoint: { type: String, default: '' },
        translations: { type: Object, default: () => { } },
        listingClass: { type: String, default: '' },
        categoryId: { type: Number, default: 0 }, // 0 means false
    },
    data() {
        return {
            clickCount: 0,
            loadPage: 1,
            showBtn: true,
            articleContent: null,
            reactivity
        };
    },
    computed: {
        endpointUrl() {
            if (!!this.categoryId) return this.endpoint + "?catId=" + this.categoryId + "&page=" + this.loadPage;
            else return this.endpoint + "?page=" + this.loadPage
        }
    },
    methods: {
        async loadMoreAricles() {
            this.loadPage++;
            this.clickCount++;
            await axios
                .get(this.endpointUrl)
                .then((response) => {
                    response.data.html.forEach(element => {
                        this.reactivity.loadMoreButtonList.push(element);
                    });
                    if (this.clickCount == response.data.total) this.showBtn = false;
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                })
        },
    }
};
</script>

<style lang="scss" scoped>
.load-more-button {
    display: flex;
    margin-top: var(--spacing-400);
    padding: var(--spacing-400) 0;

    @include breakpoint(t) {
        margin-top: var(--spacing-800);
    }
}
</style>
