import { debounce } from "lodash";
import axios from "axios";

export const initializePreview = function () {
    let currentActiveSlider = null;
    let currentIntervalId = null;

    const updateImageDisplay = (slider, currentIndex) => {
        const images = slider.querySelectorAll("img");
        images.forEach((img, index) => {
            img.classList.toggle("active", index === currentIndex);
        });
    };

    const startAutoplay = (slider) => {
        // console.log(`Starting slider: ${slider}`);
        if (currentActiveSlider && currentActiveSlider !== slider) {
            stopAutoplay();
        }

        slider.parentElement.classList.add("autoplay");

        let currentIndex = 0;
        const nextImage = () => {
            const images = slider.querySelectorAll("img");
            currentIndex = (currentIndex + 1) % images.length;
            updateImageDisplay(slider, currentIndex);
        };

        updateImageDisplay(slider, currentIndex);
        currentIntervalId = setInterval(nextImage, 1000);
        currentActiveSlider = slider;
    };

    const stopAutoplay = () => {
        // console.log(`Stopping slider: ${currentActiveSlider}`);
        if (currentIntervalId) {
            clearInterval(currentIntervalId);
            currentIntervalId = null;
        }
        if (currentActiveSlider) {
            const images = currentActiveSlider.querySelectorAll("img");
            images.forEach((img) => img.classList.remove("active"));
            images[0]?.classList.add("active");
            currentActiveSlider.parentElement.classList.remove("autoplay");
            currentActiveSlider = null;
        }
    };

    const setupImageSlider = (slider, images) => {
        // console.log(`Setting up image slider for: ${slider}`);
        images.shift(); // Remove the first image
        images.forEach((image) => {
            const img = document.createElement("img");
            img.src = image.src;
            img.srcset = `${image.retina} 2x`;
            slider.appendChild(img);
        });
        slider.setAttribute("data-fill", "true");
    };

    const setupVideoSlider = (slider, videoSrc) => {
        console.log(`Setting up video slider for: ${slider}`);
        const video = document.createElement("video");
        video.src = videoSrc;
        video.muted = true;
        video.controls = false;
        video.autoplay = true;
        video.playsInline = true;
        video.loop = true;
        slider.appendChild(video);
        slider.setAttribute("data-fill", "true");
        video.play();

        if (video) {
            video.play().catch((error) => {
                if (error.name === "NotAllowedError") {
                    // Autoplay was prevented, handle user interaction to play the video
                    // console.log(
                    //     "Autoplay prevented, waiting for user interaction"
                    // );
                    video.addEventListener(
                        "click",
                        () => {
                            video.play();
                        },
                        { once: true }
                    );
                }
            });
        }

        slider.addEventListener("mouseenter", () => {
            video.play();
        });

        slider.addEventListener("mouseleave", () => {
            video.pause();
        });
    };

    const debouncedFetchGallery = debounce(async (galleryId, slider) => {
        try {
            slider.parentElement.classList.add("loading");
            const response = await axios.get(
                `/api/site-preview?id=${galleryId}`
            );
            if (response.status === 200) {
                slider.parentElement.classList.remove("loading");
                if (response.data.gallery) {
                    setupImageSlider(slider, response.data.gallery);
                }
                if (response.data.video) {
                    setupVideoSlider(slider, response.data.video);
                }
            }
        } catch (error) {
            slider.parentElement.classList.remove("loading");
            // console.error(`Error initializing sliders: ${error}`);
        }
    }, 300);

    const initAutoplay = (slider) => {
        // console.log(`Initializing slider: ${slider}`);
        if (slider.getAttribute("data-fill") === "true") {
            return;
        }
        const galleryId = slider.getAttribute("data-key");
        debouncedFetchGallery(galleryId, slider);
    };

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    // Create the middle marker elements
    const middleMarker = document.createElement("div");
    middleMarker.classList.add("middle-marker");

    const middleSection = document.createElement("div");
    middleSection.classList.add("middle-section");

    // Append the markers to the body
    document.body.appendChild(middleMarker);
    document.body.appendChild(middleSection);

    // Observer callback
    const observerCallback = (entries) => {
        // Remove any existing visualRect
        const existingVisualRect = document.querySelector(
            ".visual-slider-rect"
        );
        if (existingVisualRect) {
            existingVisualRect.remove();
        }

        entries.forEach((entry) => {
            const slider = entry.target;
            const isIntersecting = entry.isIntersecting;
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;

            if (isIntersecting) {
                const sliderRect = slider.getBoundingClientRect();

                // Create a div to represent the sliderRect
                const visualRect = document.createElement("div");
                // visualRect.classList.add("visual-slider-rect");
                // visualRect.style.position = "fixed"; // Fix the position
                // visualRect.style.top = `${(viewportHeight - 300) / 2}px`; // Center vertically
                // visualRect.style.left = `${(viewportWidth - 200) / 2}px`; // Center horizontally
                // visualRect.style.width = `200px`; // Set the width to 200px
                // visualRect.style.height = `300px`; // Set the height to 300px
                // document.body.appendChild(visualRect);
                // Logging for debugging purposes
                // console.log("sliderRect :>> ", sliderRect);
                // console.log("visualRect :>> ", {
                //     top: (viewportHeight - 300) / 2,
                //     left: (viewportWidth - 200) / 2,
                //     width: 200,
                //     height: 300,
                // });

                // Calculate the start and end points of the 100% x 300px rectangle with bottom edge at the middle of the screen
                const sliderMiddle = (sliderRect.top + sliderRect.bottom) / 2;
                const middleSectionEnd = viewportHeight / 2;
                const middleSectionStart = middleSectionEnd - 300;

                // console.log("middleSectionStart :>> ", middleSectionStart);
                // console.log("middleSectionEnd :>> ", middleSectionEnd);

                if (
                    sliderMiddle >= middleSectionStart &&
                    sliderMiddle <= middleSectionEnd
                ) {
                    // Start autoplay only if autoplay isn't already active for this slider
                    if (!slider.classList.contains("autoplay")) {
                        initAutoplay(slider);
                        startAutoplay(slider);
                        slider.parentElement.classList.add("autoplay");
                    }
                }
            } else {
                // Stop autoplay only if autoplay is active for this slider
                if (slider.parentElement.classList.contains("autoplay")) {
                    stopAutoplay(slider);
                    slider.parentElement.classList.remove("autoplay");
                }
            }
        });
    };

    // Observer options
    const options = {
        root: null, // Use the viewport as the root
        rootMargin: "0px",
        threshold: 0.5, // Trigger when at least 10% of the element is visible
    };

    // Create the Intersection Observer instance
    const observer = new IntersectionObserver(observerCallback, options);

    document
        .querySelectorAll(".js-autoImageSlider:not([data-preview='true'])")
        .forEach((sliderElement) => {
            sliderElement.setAttribute("data-preview", "true");
            sliderElement.addEventListener("mouseenter", () => {
                initAutoplay(sliderElement);
                startAutoplay(sliderElement);
            });
            sliderElement.addEventListener("mouseleave", () => {
                stopAutoplay();
            });
            if (isMobileDevice()) {
                observer.observe(sliderElement);
            }
        });

    // Add scroll event listener to stop slider on scroll
    document.addEventListener("scroll", () => {
        // console.log(`Scroll event: stopping slider`);
        stopAutoplay();
    });
};
