<template>
	<button @click="toggleSwitch" class="switch" :class="{ on, disabled }" type="button">
		<div class="switch__toggle"></div>
        <div class="switch__icons">
            <div class="switch__icon" :class="{active: !on}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12.0001 3.99756V5.21697M12.0001 18.7775V19.9969M20 11.9973H18.7805M5.21947 11.9973H4M17.6567 6.34041L16.7945 7.20266M7.20565 16.7915L6.34338 17.6538M17.6567 17.6538L16.7945 16.7915M7.20565 7.20307L6.34338 6.34084M12.0002 8.26821C14.0603 8.26821 15.7295 9.9382 15.7295 11.9974C15.7295 14.0574 14.0603 15.7274 12.0002 15.7274C9.94003 15.7274 8.27083 14.0574 8.27083 11.9974C8.27083 9.9382 9.94003 8.26821 12.0002 8.26821Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="switch__icon" :class="{active: on}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 12.6347C19.5814 17.8765 14.0999 21.7094 8.43669 19.2196C6.78997 18.4957 5.45981 17.1569 4.74975 15.5042C2.32032 9.85337 6.13094 4.42566 11.3548 3.99756C10.8574 6.24094 11.4767 8.85967 13.1087 10.4916C14.7416 12.1253 17.5706 13.1822 20 12.6347Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
	</button>
</template>

<script>
import axios from "axios";
import { reactivity } from "@/store/reactivity";

export default {
	name: 'ThemeSwitch',
	props: {
		disabled: { type: Boolean, default: false },

	},
	data() {
		return {
			on: false,
            reactivity
		}
	},
	methods: {
        setColorScheme(themeName) {
            axios.put('/api/color-theme', {
				theme: themeName,
			})
        },
		toggleSwitch() {
			this.on = !this.on;
			this.setColorScheme(this.on ? 'dark' : 'light');
			document.body.setAttribute('data-theme', (this.on ? 'dark': 'light'));
		}
	},
	mounted() {
        if(this.reactivity.themeCookie == 'dark') this.on = true;
        if(this.reactivity.themeCookie == 'light') this.on = false;
	}
};
</script>

<style lang="scss" scoped>
.switch {
    display: flex;
    width: 64px;
    height: 32px;
    border-radius: var(--radius-1000);
    background: var(--background-tertiary);
    padding: var(--spacing-25);
    transition: background 0.2s;
    position: relative;

    &__toggle {
        display: block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: var(--background-primary);
        transform: translateX(0);
        transition: transform 0.3s;
    }

    &__icons {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacing-50);
        padding: var(--spacing-25);
    }

    &__icon {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        pointer-events: none;
        flex-shrink: 0;

        path {
            transition: stroke .6s;
            stroke: var(--element-icon-secondary);
        }

        &.active {
            path {
                transition: stroke .6s;
                stroke: var(--element-icon-primary);
            }
        }
    }

    &.on {
        .switch {
            &__toggle {
                transform: translateX(32px);
            }
        }
    }

    &.disabled {
        cursor: no-drop;
        opacity: 0.8;
    }
}
</style>
