<template>
    <div
        :class="modifiers.map((m) => 'networks--' + m)"
        class="share"
        @click.stop
    >
        <button
            @click="openMenu()"
            class="button button--square button--rounded"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1311 11.7179C10.1311 15.8974 8.75656 19.1331 5 20.9227C5 20.9227 15.3103 22.4698 15.3103 11.7179C15.3103 11.7179 16.8299 11.717 18.1376 11.716C18.871 11.715 19.2684 10.8107 18.7939 10.2215L13.238 3.32231C12.9011 2.90436 12.2938 2.8895 11.9435 3.29755C10.6405 4.81286 7.71245 8.22085 6.0074 10.2056C5.50653 10.789 5.90289 11.7179 6.65044 11.7179H10.1311Z"
                    stroke="#191919"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </button>
        <div
            class="share-menu"
            v-if="isOpened"
            :class="'share-menu--direction-' + direction"
        >
            <h4 class="share-menu__title">
                {{ reactivity?.translations?.shareMenuTitle }}
            </h4>
            <div class="share-menu__list">
                <ShareNetwork
                    @open="collectData()"
                    v-for="item in networks"
                    :network="item.network"
                    :key="item.network"
                    :url="shareUrl"
                    title="Find any and every adult entertainment genre at "
                    :hashtags="shareTags"
                    class="share-menu__item button button--small button--curved button--transparent"
                >
                    <div class="share-menu__item__icon">
                        <img :src="item.icon" :alt="item.name" />
                    </div>
                    <span v-if="titles">{{ item.name }}</span>
                </ShareNetwork>
            </div>
        </div>
    </div>
</template>

<script>
import { reactivity } from "@/store/reactivity";
import axios from "axios";

export default {
    name: "ActionShare",
    props: {
        modifiers: { type: Array, default: () => [] },
        tags: { type: Array, default: () => [] },
        actionEventName: { type: String, default: "share" },
        itemId: { type: Number, default: null },
        titles: { type: Boolean, default: true },
        direction: { type: String, default: "up" },
    },
    data() {
        return {
            isOpened: false,
            resMessage: "",
            reactivity,
        };
    },
    computed: {
        getUTM(network) {
            return "?utm_source=socials&utm_medium=" + network.name;
        },
        shareUrl() {
            const path = "https://pornsitesall.com/";
            return path;
        },
        shareTags() {
            let tags = this.tags;
            if (tags && tags.length)
                return tags
                    .map((tag) => tag.name)
                    .join(",")
                    .toLowerCase();
            else return "PornSiteAall,allpornsites,pornsites";
        },
        networks() {
            return [
                {
                    network: "twitter",
                    name: "Twitter",
                    icon: "../../img/icons/social/twitter_round.svg",
                },
                {
                    network: "reddit",
                    name: "Reddit",
                    icon: "../../img/icons/social/reddit.svg",
                },
                {
                    network: "facebook",
                    name: "Facebook",
                    icon: "../../img/icons/social/facebook.svg",
                },
                {
                    network: "pinterest",
                    name: "Pinterest",
                    icon: "../../img/icons/social/pinterest.svg",
                },
                {
                    network: "whatsapp",
                    name: "Whatsapp",
                    icon: "../../img/icons/social/whatsapp.svg",
                },
                {
                    network: "messenger",
                    name: "Messenger",
                    icon: "../../img/icons/social/messenger.svg",
                },
                {
                    network: "viber",
                    name: "Viber",
                    icon: "../../img/icons/social/viber.svg",
                },
                {
                    network: "sms",
                    name: "SMS",
                    icon: "../../img/icons/social/sms.svg",
                },
                {
                    network: "telegram",
                    name: "Telegram",
                    icon: "../../img/icons/social/telegram.svg",
                },
            ];
        },
        collectData() {
            axios
                .put("/data/collect", {
                    actionEventName: this.actionEventName,
                    domain: this.reactivity.domain,
                    itemId: Number(this.itemId),
                    fp: reactivity?.fingerPrint,
                    btz: reactivity?.timeZone,
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.resMessage = response.data.message;
                    }
                });
        },
    },
    methods: {
        openMenu() {
            this.isOpened = !this.isOpened;
        },
        closeListener() {
            document.addEventListener("click", () => {
                this.isOpened = false;
            });
            // document.addEventListener('click', this.forceClose, { once: true });
            // document.addEventListener('scroll', this.forceClose, { once: true });
        },
    },
    mounted() {
        if (!this.isOpened) {
            this.closeListener();
        }
    },
};
</script>

<style lang="scss" scoped>
.share {
    position: relative;

    &-menu {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: var(--spacing-75);
        gap: var(--spacing-75);
        background: #fff;
        position: absolute;
        margin-bottom: var(--spacing-200);
        border-radius: var(--radius-150);
        border: 1px solid var(--border-secondary);
        background: var(--background-primary);
        box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05),
            0 10px 15px -3px rgba(0, 0, 0, 0.1);

        &__title {
            padding: var(--spacing-150) 0;
            text-align: center;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: var(--spacing-75);
        }

        &__item {
            justify-content: flex-start;

            &__icon {
                width: 16px;
                height: 16px;
                flex-shrink: 0;

                & > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &--direction {
            &-up {
                bottom: 100%;
                right: 0;
            }

            &-down {
                top: 100%;
                right: 0;
            }

            &-left {
                right: calc(100% + 12px);
                bottom: -40px;
            }
        }
    }
}
</style>
