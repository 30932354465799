// store.js
import { reactive } from "vue";

export const reactivity = reactive({
    lang: null,
    type: "",
    domain: "",
    fileId: "",
    hamburger: false,
    hamburgerMenu: false,
    cremaSLideUp: false,
    cremaSearch: false,
    hamburgerMenuContent: [],
    loadMoreButtonList: [],
    langDropdownData: [],
    themeCookie: "",
    settings: {
        actionUrl: "",
    },
    fingerPrint: "",
    cSlideDown: false,
    cSlideDownSearchBtn: true,
    // separe translations in future
    translations: [],
    timeZone: "",
});
