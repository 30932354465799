<template>
    <div class="column gap--300" v-if="galleryThumbs">
        <div v-if="combinedMedia.length > 0" class="gallery-display">
            <a
                class="gallery-display__main mobile-only"
                @touchstart="touchStart"
                @touchend="touchEnd"
                target="_blank"
                rel="nofollow"
                :href="url"
            >
                <video
                    ref="videoDesktop"
                    autoplay
                    loop
                    muted
                    playsinline
                    :controls="false"
                    :src="currentMediaObj.src"
                    type="video/mp4"
                    v-if="currentMediaObj.type === 'video'"
                    @touchstart.prevent="togglePlayPause"
                ></video>
                <img
                    v-else
                    :src="currentMediaObj.src"
                    :alt="`image ${currentMedia + 1}`"
                />

                <div
                    v-if="currentMediaObj.type === 'video'"
                    class="thumbnail__item__controls row gap--100 p-a--200"
                >
                    <button
                        class="button button--rounded button--square button--semi-transparent-dark"
                        @click="togglePlayPause"
                    >
                        <svg
                            v-if="isPlaying"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#191919"
                                d="M7 6a2 2 0 1 1 4 0v12a2 2 0 1 1-4 0V6Zm6 0a2 2 0 1 1 4 0v12a2 2 0 1 1-4 0V6Z"
                            />
                        </svg>
                        <svg
                            v-else
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#191919"
                                stroke="#191919"
                                d="M17.383 10.316a2 2 0 0 1 0 3.365l-8.301 5.336C7.75 19.873 6 18.917 6 17.335V6.662c0-1.583 1.75-2.538 3.082-1.683l8.301 5.337Z"
                            />
                        </svg>
                    </button>
                </div>

                <div class="gallery__nav" v-if="mq.current === 'phone'">
                    <div
                        v-if="combinedMedia.length > 1"
                        class="button-wrapper"
                        @click="clickLeft"
                        @touchstart.prevent="clickLeft"
                        @mousedown.stop
                    >
                        <button
                            class="button button--no-padding button--width--400 button--height--800 button--rounded button--semi-transparent-dark"
                            data-nav="previous"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16 20L8 12L16 4"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>

                    <div
                        v-if="combinedMedia.length > 1"
                        class="button-wrapper"
                        @click="clickRight()"
                        @touchstart.prevent="clickRight"
                        @mousedown.stop
                    >
                        <button
                            class="button button--no-padding button--width--400 button--height--800 button--rounded button--semi-transparent-dark"
                            data-nav="next"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 4L16 12L8 20"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </a>
        </div>

        <div
            class="thumbnails"
            :class="[
                mq.current === 'phone'
                    ? 'row gap--100 p-lr--300 scroll-area scroll-area--custom'
                    : 'grid gap--200',
            ]"
        >
            <div
                v-for="(item, index) in combinedMedia"
                :key="'thumb-' + index"
                class="thumbnail"
                :class="{ 'is-vertical': isVertical && index === 0 }"
                @click="changeThumb(index)"
            >
                <div
                    class="thumbnail__item thumbnail__item--video"
                    v-if="item.type === 'video'"
                >
                    <video
                        ref="videoMobile"
                        :autoplay="mq.current === 'phone' ? false : true"
                        loop
                        muted
                        paused
                        :controls="false"
                        :src="video"
                        type="video/mp4"
                    ></video>
                    <div
                        v-if="mq.current !== 'phone'"
                        class="thumbnail__item__controls row gap--100 p-a--200"
                    >
                        <button
                            class="button button--rounded button--square button--semi-transparent-dark"
                            @click="togglePlayPause"
                            @touchstart.prevent="togglePlayPause"
                        >
                            <svg
                                v-if="isPlaying"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="#191919"
                                    d="M7 6a2 2 0 1 1 4 0v12a2 2 0 1 1-4 0V6Zm6 0a2 2 0 1 1 4 0v12a2 2 0 1 1-4 0V6Z"
                                />
                            </svg>
                            <svg
                                v-else
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="#191919"
                                    stroke="#191919"
                                    d="M17.383 10.316a2 2 0 0 1 0 3.365l-8.301 5.336C7.75 19.873 6 18.917 6 17.335V6.662c0-1.583 1.75-2.538 3.082-1.683l8.301 5.337Z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>

                <div v-else class="thumbnail__item thumbnail__item--image">
                    <img
                        :src="item.src"
                        :srcset="item.retina + ' 2x'"
                        :alt="`thumbnail ${index + 1}`"
                        class="thumbnail__item"
                        @click="desktopLink"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Gallery",
    inject: ["mq"],
    props: {
        gallery: { type: Array, default: () => [] },
        btn: { type: String, default: "" },
        url: { type: String, default: "" },
        video: { type: String, default: null },
        galleryBtn: { type: Boolean, default: false },
        galleryThumbs: { type: Boolean, default: false },
        isVertical: { type: Boolean, default: false },
    },
    data() {
        return {
            currentMedia: 0,
            isPlaying: true,
            isMuted: true,
            startPoint: null,
            swipeDirection: null,
        };
    },
    computed: {
        combinedMedia() {
            let media = this.gallery.map((item) => ({
                type: "image",
                src: item.src,
                retina: item.retina,
            }));

            if (this.video) {
                media.unshift({
                    type: "video",
                    src: this.video,
                });
            }
            return media;
        },
        currentMediaObj() {
            return this.combinedMedia.length > 0
                ? this.combinedMedia[this.currentMedia]
                : null;
        },
    },
    methods: {
        changeThumb(index) {
            this.currentMedia = index;
        },
        togglePlayPause() {
            ["videoDesktop", "videoMobile"].forEach((ref) => {
                const videos = Array.isArray(this.$refs[ref])
                    ? this.$refs[ref]
                    : [this.$refs[ref]];
                videos.forEach((video) => {
                    if (video && video.paused) {
                        video.play();
                        this.isPlaying = true;
                    } else {
                        video.pause();
                        this.isPlaying = false;
                    }
                });
            });
        },
        clickLeft() {
            this.currentMedia =
                this.currentMedia < this.gallery.length - 1
                    ? this.currentMedia + 1
                    : 0;
        },
        clickRight() {
            this.currentMedia =
                this.currentMedia > 0
                    ? this.currentMedia - 1
                    : this.gallery.length - 1;
        },
        getPointFromEvent(event) {
            let point;
            if (event.targetTouches && event.targetTouches[0]) {
                point = {
                    x: event.targetTouches[0].clientX,
                    y: event.targetTouches[0].clientY,
                };
            } else if (event.changedTouches && event.changedTouches[0]) {
                point = {
                    x: event.changedTouches[0].clientX,
                    y: event.changedTouches[0].clientY,
                };
            } else {
                point = { x: event.clientX, y: event.clientY };
            }
            return point;
        },
        touchStart(event) {
            this.startPoint = this.getPointFromEvent(event);
        },
        touchEnd(event) {
            const endPoint = this.getPointFromEvent(event);

            const scrolling = Math.abs(this.startPoint.y - endPoint.y) > 20;
            const swiping = Math.abs(this.startPoint.x - endPoint.x) > 20;
            if (!scrolling && swiping) {
                this.swipeDirection =
                    endPoint.x > this.startPoint.x ? "right" : "left";
                window.requestAnimationFrame(this.onSwipe);
            }
            this.startPoint = { x: 0, y: 0 }; // Reset startPoint to initial values
        },
        onSwipe() {
            if (this.swipeDirection === "right") {
                this.clickRight();
            } else if (this.swipeDirection === "left") {
                this.clickLeft();
            }

            setTimeout(() => {
                this.swipeDirection = null;
            }, 10);
        },
        desktopLink() {
            if (this.mq.current == "desktop") window.open(this.url, "_blank");
        },
    },
};
</script>

<style lang="scss" scoped>
.gallery-display {
    position: relative;
    display: flex;
    width: 100%;

    &__main {
        display: flex;
        width: 100%;
        aspect-ratio: 1/1;

        & > img,
        & > video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @include breakpoint(t) {
        display: none;
    }
}

.thumbnails {
    --l-scroller-gap: var(--spacing-100);

    @include breakpoint(t) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.thumbnail {
    display: flex;
    height: 50px;
    aspect-ratio: 16/9;

    &__item {
        height: 100%;
        width: 100%;
        border-radius: var(--radius-75);
        overflow: clip;
        position: relative;
        cursor: pointer;

        & > img,
        & > video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &__controls {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
        }
    }

    @include breakpoint(t) {
        aspect-ratio: unset;
        height: auto;
        max-height: calc(
            100dvh - var(--header-height) - var(--spacing-300) - 21px -
                var(--spacing-300) - var(--spacing-300)
        );

        & > img {
            height: auto;
        }

        &:not(.is-vertical):first-child {
            grid-column: 1/-1;
        }
    }
}
</style>
