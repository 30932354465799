<template>
    <transition name="slide-up-animation">
        <div v-scroll-lock="opened" v-if="opened" class="slide-up-holder">
            <div class="backdrop" @click="closeSlideUp"></div>
            <div
                class="slide-up-wrapper"
                ref="base"
                @mousedown.stop=""
                @touchstart="startHandler"
                @touchmove.stop="movingHandler"
                @touchend="stopHandler"
            >
                <button
                    v-if="mq.current === 'phone'"
                    class="slide-up__drag-bar"
                    ref="dragbar"
                    @click="closeSlideUp"
                >
                    <span class="grab"></span>
                </button>

                <div class="slide-up__close">
                    <button
                        class="button button button--curved button--square"
                        :class="
                            mq.current !== 'desktop'
                                ? ' button--semi-transparent-dark'
                                : 'button--transparent'
                        "
                        @click="closeSlideUp"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 4L20 20M20 4L4 20"
                                stroke="#191919"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>

                <div class="slide-up__container">
                    <div class="slide-up__hero site-detail__hero">
                        <!-- <video
                            v-if="content?.heroVideo"
                            autoplay
                            loop
                            muted
                            playsinline
                            :controls="false"
                            :src="content?.heroVideo"
                            type="video/mp4"
                        ></video> -->

                        <img
                            v-if="content?.heroImage?.normal"
                            :src="content?.heroImage?.normal"
                            :srcset="content?.heroImage?.retina + ' 2x'"
                            alt="Hero"
                        />

                        <div v-else class="icon-placeholder">
                            <img
                                class="base"
                                :src="content?.logo?.normal"
                                alt="Logo"
                            />
                            <img
                                class="icon"
                                :src="content?.logo?.normal"
                                :srcset="content?.logo?.retina + ' 2x'"
                                alt="Logo"
                            />
                        </div>
                    </div>

                    <div
                        class="slide-up__content p-a--300 p-a--t--500 p-a--d--800"
                    >
                        <div class="site-detail__intro static column gap--300">
                            <div class="row row--space-between gap--150">
                                <div class="row gap--150">
                                    <div class="site-detail__icon">
                                        <img
                                            :src="content?.logo?.normal"
                                            :srcset="
                                                content?.logo?.retina + ' 2x'
                                            "
                                            alt="Logo"
                                        />
                                    </div>
                                    <h1 class="h2">{{ content?.name }}</h1>
                                </div>
                                <div class="row gap--0 row--self-end">
                                    <div
                                        v-if="content?.is_new"
                                        class="label"
                                        style="
                                            --custom-color: var(
                                                --state-error-default
                                            );
                                        "
                                    >
                                        New
                                    </div>
                                    <div
                                        v-if="content?.is_popular"
                                        class="label"
                                        style="
                                            --custom-color: var(
                                                --state-focus-default
                                            );
                                        "
                                    >
                                        Trending
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="content?.tags && content?.tags.length"
                                class="row site-detail__tags"
                            >
                                <a
                                    :href="tag?.detailUrl"
                                    v-for="(tag, i) in firstThreeTags"
                                    :key="'t-' + i"
                                    class="button button--small button--curved"
                                >
                                    <img
                                        src="@@@/pornsitesall/general/hashtag.svg"
                                        alt="hashtag"
                                        width="16"
                                        height="16"
                                    />
                                    <span>{{ tag.name }}</span>
                                </a>

                                <a
                                    v-if="content?.tags.length"
                                    :href="content?.detailUrl"
                                    class="button button--small button--curved"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M3.33331 8H3.33398M12.6666 8H12.6673M7.99998 8H8.00065"
                                            stroke="#DD447A"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </a>
                            </div>

                            <div v-if="content?.description" class="row">
                                <a
                                    :href="content?.detailUrl"
                                    class="p2 text--c-primary line-clamp"
                                >
                                    {{ content?.description }}
                                </a>
                            </div>

                            <div class="row gap--200">
                                <a
                                    :href="content?.detailUrl"
                                    rel="nofollow"
                                    class="button button--brand--primary button--rounded button--width"
                                >
                                    <span>{{
                                        reactivity?.translations?.psaModalBtn2
                                    }}</span>
                                </a>

                                <div class="row gap--200 row--self-end">
                                    <div class="row gap--200 row--self-end">
                                        <action-like
                                            :itemId="content?.id"
                                            :action-event-name="'like-site'"
                                        />
                                        <action-share
                                            :itemId="content?.id"
                                            :action-event-name="'share-site'"
                                            :tags="content?.tags"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { reactivity } from "@/store/reactivity";
import axios from "axios";

export default {
    name: "SlideUp",
    data() {
        return {
            opened: false,
            reactivity,
            content: null,
            tartPoint: null,
            currentPoint: null,
            posY: 0,
            lastPoint: null,
            dragging: false,
            closing: false,
            slidingPx: 0,
            allowGesture: false,
            ticking: false,
            init_to: null,
            initCounter: 0,
            initScroll_counter: 0,
            scrolling_to: false,
            scrolling: false,
        };
    },
    inject: ["mq", "emitter"],
    computed: {
        firstThreeTags() {
            return this.content.tags.slice(0, 3);
        },
        isIOS() {
            const userAgent = window.navigator.userAgent.toLowerCase();
            const iosPlatforms = /(iphone|ipad|ipod)/i;
            return !!iosPlatforms.test(userAgent);
        },
    },
    methods: {
        closeSlideUp() {
            this.opened = false;
            this.unfixIOSScroll();
        },
        getPointFromEvent($event) {
            let point;
            if ($event.targetTouches && $event.targetTouches[0]) {
                point = {
                    x: $event.targetTouches[0].clientX,
                    y: $event.targetTouches[0].clientY,
                };
            } else if ($event.changedTouches && $event.changedTouches[0]) {
                point = {
                    x: $event.changedTouches[0].clientX,
                    y: $event.changedTouches[0].clientY,
                };
            } else {
                point = { x: $event.clientX, y: $event.clientY };
            }
            return point;
        },
        startHandler(s) {
            this.startPoint = this.getPointFromEvent(s);
        },
        animFrameMoving() {
            this.slidingPx = Math.max(
                this.currentPoint.y - this.startPoint.y,
                0
            );
            this.ticking = false;
        },
        animFrameEnd() {
            if (this.closing) this.slidingPx = this.$refs.base?.clientHeight;
            else this.slidingPx = 0;
        },
        movingHandler(d) {
            this.currentPoint = this.getPointFromEvent(d);

            if (!this.dragging) {
                this.dragging =
                    Math.abs(this.currentPoint.y - this.startPoint.y) > 10;
            } else {
                this.closing = this.currentPoint.y > this.lastPoint.y;
                if (!this.ticking) {
                    window.requestAnimationFrame(this.animFrameMoving);
                    this.ticking = true;
                }
            }
            this.lastPoint = this.currentPoint;
        },
        stopHandler() {
            this.dragging = false;
            window.requestAnimationFrame(this.animFrameEnd);

            if (this.closing) {
                setTimeout(() => {
                    this.closeSlideUp();
                }, 400);
            } else {
                setTimeout(() => {
                    this.closing = false;
                    this.currentPoint = null;
                    this.lastPoint = null;
                    this.startPoint = null;
                }, 500);
            }
        },
        fixIOSScroll() {
            // helps prevent url bar collapse/expand on slideup scroll
            if ((navigator.maxTouchPoints || 0) > 0 || this.isIOS) {
                this.posY = window.scrollY;
                document.body.style.position = "fixed";
                document.body.style.top = `-${this.posY}px`;
                document.documentElement.classList.add("no-bounce");
            }
        },
        unfixIOSScroll() {
            if ((navigator.maxTouchPoints || 0) > 0 || this.isIOS) {
                document.body.style.position = "";
                document.body.style.top = "";
                window.scrollTo(0, this.posY);
                document.documentElement.classList.add("no-bounce");
            }
        },
        handleModalOpen(e) {
            const btnModal = e.currentTarget;
            this.reactivity.cremaSLideUp = null
                ? (this.active = true)
                : (this.active = !this.active);

            const siteId = btnModal.getAttribute("data-id");

            axios
                .post(`/data/site?key=${siteId}`, {
                    fileId: this.reactivity.fileId,
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.content = response.data;
                        this.opened = true;
                        this.fixIOSScroll();
                    }
                });
        },
        onScroll() {
            this.scrolling = true;
            if (this.scrolling_to) clearTimeout(this.scrolling_to);

            this.scrolling_to = setTimeout(() => {
                this.scrolling = false;
            }, 100);
        },
        openFromHTML() {
            const btnModals = document.querySelectorAll(
                ".js-show-modal:not([data-init='true'])"
            );
            let preventDoubleClick = false;

            if (btnModals.length) {
                btnModals.forEach((btnModal) => {
                    btnModal.addEventListener("click", (e) => {
                        if (!preventDoubleClick && !this.scrolling) {
                            this.handleModalOpen(e);
                        }
                        preventDoubleClick = false;
                    });
                    // Mark the element as initialized
                    btnModal.setAttribute("data-init", "true");
                });
            }
        },
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll, { passive: true });
        this.$nextTick(() => {
            this.openFromHTML();
        });
    },
    created() {
        // from load more sites component
        this.emitter.on("fetchingData", () => {
            this.openFromHTML();
        });
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
        if (this.scrolling_to) clearTimeout(this.scrolling_to);
    },
};
</script>

<style lang="scss" scoped>
.slide-up {
    &-holder {
        height: 100vh;
        height: 100dvh;
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        z-index: 99;
        align-items: flex-end;
        display: flex;
        justify-content: center;

        .backdrop {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.75);
            backdrop-filter: blur(5px);
        }

        &.popup-inside {
            background: transparent;
        }

        @include breakpoint(t) {
            align-items: center;
        }
    }

    &-wrapper {
        position: relative;
        width: 100%;
        max-width: $slide-up-mw;

        @include breakpoint(d) {
            max-width: 1000px;
        }
    }

    &__drag-bar {
        display: flex;
        height: 32px;
        justify-content: center;
        align-items: flex-start;
        padding-top: var(--spacing-100);
        flex-shrink: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;

        .grab {
            display: inline-block;
            width: 150px;
            height: 5px;
            background: var(--color-transparent-white-75);
            border-radius: var(--radius-1000);
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        transition: opacity 0.24s ease-in;
        z-index: 2;
    }

    &__container {
        display: grid;
        grid-template-areas: "hero" "content";
        grid-template-columns: minmax(0, 1fr);
        background: var(--background-primary);
        border-radius: var(--radius-300) var(--radius-300) 0 0;
        color: var(--text-secondary);
        position: relative;
        z-index: 1;
        overflow-y: auto;
        overflow-x: clip;
        max-height: 90vh;

        @include breakpoint(t) {
            border-radius: var(--radius-300);
        }

        @include breakpoint(d) {
            grid-template-areas: "hero content";
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &__hero {
        grid-area: hero;
    }

    &__content {
        grid-area: content;
    }
}

// Site detail adjustments
.site-detail {
    &__intro {
        --clamp-rows: 2;

        @include breakpoint(d) {
            height: 100%;
            justify-content: center;
        }
    }

    &__tags {
        flex-wrap: nowrap;
        margin: 0 calc(var(--spacing-300) * -1);
        padding: 0 var(--spacing-300);
        overflow-x: auto;
        overflow-y: hidden;
        @include invisibleScrollbar;
    }
}

// ANIMATIONS
.slide-up-animation {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.24s;

        &::before {
            transition: opacity 0.24s cubic-bezier(0.2, 0, 0.05, 1);
        }

        .slide-up-wrapper {
            transition: transform 0.24s cubic-bezier(0.2, 0, 0.05, 1);
        }
    }

    &-enter-from,
    &-leave-to {
        &::before {
            opacity: 0;
        }

        .slide-up-wrapper {
            transform: translateY(100%);

            @include breakpoint(t) {
                transform: translateY(calc(50vh + 50%));
            }
        }
    }
}
</style>
