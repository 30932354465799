<template>
    <div>
        <button
            class="button button--square button--transparent"
            @click="toggleClick"
        >
            <svg
                v-if="isSearch"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M16 16L20 20M18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z"
                    stroke="#191919"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
            >
                <path
                    d="M8,8,0,16,8,8,0,0,8,8l8-8L8,8l8,8Z"
                    transform="translate(1 1)"
                    fill="none"
                    stroke="#7e7e7e"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
            </svg>
        </button>
    </div>
</template>

<script>
import { reactivity } from "@/store/reactivity";

export default {
    name: "SearchFormButton",
    inject: ["emitter"],
    data() {
        return {
            reactivity,
        };
    },
    computed: {
        isSearch() {
            return this.reactivity.cSlideDownSearchBtn;
        },
    },
    methods: {
        toggleClick() {
            this.reactivity.cSlideDownSearchBtn =
                !this.reactivity.cSlideDownSearchBtn;
            this.reactivity.cSlideDown = !this.reactivity.cSlideDown;

            if (this.reactivity.cSlideDownSearchBtn) {
                this.reactivity.hamburger = false;
                this.reactivity.hamburgerMenu = false;
            }

            this.emitter.emit("searchToggle");
        },
    },
};
</script>
