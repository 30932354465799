<template>
    <div class="hamburger" @click="toggleHamburgerMenu" v-scroll-lock="active">
        <transition name="hamburger">
            <div v-if="this.reactivity.hamburger" class="hamburger-wrapper">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M4 4L20 20M20 4L4 20"
                        stroke="#191919"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <div v-else class="hamburger-wrapper">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M3 19H21M3 5H21M3 12H21"
                        stroke="#191919"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </transition>
    </div>
</template>

<script>
import { reactivity } from "@/store/reactivity";

export default {
    name: "Hamburger",
    props: {},
    data() {
        return {
            active: null,
            reactivity,
        };
    },
    methods: {
        toggleHamburgerMenu() {
            this.reactivity.hamburger = null
                ? (this.active = true)
                : (this.active = !this.active);
            this.reactivity.hamburgerMenu = this.reactivity.hamburger;
            document.body.classList.toggle("hamburger-opened");
        },
    },
};
</script>

<style lang="scss" scoped>
.hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    position: relative;
    cursor: pointer;

    &-wrapper {
        width: 24px;
        height: 24px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.5s;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
    }

    &-enter-to,
    &-leave-from {
        opacity: 1;
    }
}
</style>
