<template>
    <div
        :class="[
            'search-container',
            modifiers.map((m) => 'search-container--' + m),
            { focused: isSearchFocused },
        ]"
        @click.stop=""
    >
        <div class="search" :action="searchActionUrl">
            <label class="search-input">
                <div class="search-input__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M16 16L20 20M18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z"
                            stroke="#191919"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>

                <input
                    type="text"
                    name="search"
                    autocomplete="off"
                    :placeholder="
                        mq.current !== 'phone'
                            ? reactivity.translations.searchPlaceholder
                            : reactivity.translations.searchPlaceholderMobile
                    "
                    v-model="query"
                    @keyup="startSearch"
                    @keyup.up.prevent=""
                    @keyup.down.prevent=""
                    @keydown.esc="clearFld"
                    @keyup.enter="submitForm"
                    @click="onSearchFocus($event)"
                    @blur="isSearchFocused = false"
                    ref="bsearch"
                />
                <div class="search-input__status">
                    <div
                        v-if="searchValid && !isLoading"
                        class="status status--cross"
                        @click="blurSearch"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                        >
                            <path
                                d="M8,8,0,16,8,8,0,0,8,8l8-8L8,8l8,8Z"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#7e7e7e"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <div v-if="isLoading" class="status status--loader">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.001"
                            height="17.001"
                            viewBox="0 0 17.001 17.001"
                        >
                            <g transform="translate(-334.5 -102.5)">
                                <path
                                    d="M8,16A8,8,0,0,1,4.886.629a8,8,0,0,1,6.228,14.743A7.95,7.95,0,0,1,8,16ZM8,1.25A6.752,6.752,0,0,0,5.373,14.22a6.752,6.752,0,0,0,5.255-12.44A6.708,6.708,0,0,0,8,1.25Z"
                                    transform="translate(335 103)"
                                    fill="#c3c3c3"
                                    stroke="#c3c3c3"
                                />
                                <path
                                    d="M6.423,11.676a.621.621,0,0,1,.1-.333h0a6.815,6.815,0,0,0,.353-.714,6.774,6.774,0,0,0,0-5.255A6.726,6.726,0,0,0,5.424,3.227,6.729,6.729,0,0,0,.65,1.25H.625A.625.625,0,0,1,.625,0H.65A7.952,7.952,0,0,1,3.764.629,7.976,7.976,0,0,1,6.307,2.344,7.975,7.975,0,0,1,8.022,4.887a8.023,8.023,0,0,1,0,6.228,8.032,8.032,0,0,1-.4.815h0a.625.625,0,0,1-1.2-.253Z"
                                    transform="translate(342.351 103)"
                                    fill="#707070"
                                    stroke="#707070"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </label>
            <div
                v-if="searchValid && queryDone && !isLoading"
                class="search-whisper"
            >
                <ul class="search-whisper__list" v-if="hasResults">
                    <li
                        tabindex="0"
                        v-for="(item, i) in results"
                        :key="i"
                        @keyup.up="arrowUp(item, i)"
                        @keyup.down="arrowDown(item, i)"
                        @keyup.enter="goToDestination(item, i)"
                        @mouseenter="mouseEnter(item, i)"
                        :ref="'item-' + i"
                    >
                        <a
                            :href="item.url"
                            class="search-whisper__item"
                            @click="$event.stopPropagation()"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM11.7071 7.29289C11.3166 6.90237 10.6834 6.90237 10.2929 7.29289C9.90237 7.68342 9.90237 8.31658 10.2929 8.70711L13.5858 12L10.2929 15.2929C9.90237 15.6834 9.90237 16.3166 10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L11.7071 7.29289Z"
                                    fill="#3C95FF"
                                />
                            </svg>
                            <span>{{ item.name }}</span>
                        </a>
                    </li>
                </ul>

                <div
                    v-else
                    class="search-whisper__list search-whisper__list--no-results"
                >
                    <button
                        class="search-whisper__item"
                        type="button"
                        @click="submitForm"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM11.7071 7.29289C11.3166 6.90237 10.6834 6.90237 10.2929 7.29289C9.90237 7.68342 9.90237 8.31658 10.2929 8.70711L13.5858 12L10.2929 15.2929C9.90237 15.6834 9.90237 16.3166 10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L11.7071 7.29289Z"
                                fill="#3C95FF"
                            />
                        </svg>
                        <span class="text"
                            >{{ reactivity.translations.searchNoresults }}:
                            <strong>{{ query }}</strong></span
                        >
                    </button>
                </div>
            </div>
        </div>
        <button
            v-if="showSearchBtn && responsiveButton"
            @click="submitForm"
            class="button button--brand--primary"
        >
            {{ reactivity.translations.searchBtn }}
        </button>
    </div>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import { reactivity } from "../store/reactivity";
import { useMq } from "vue3-mq";
import emitter from "tiny-emitter/instance";

export default {
    name: "Search",
    props: {
        modifiers: { type: Array, default: () => [] },
        translations: { type: Array, default: () => [] },
        showSearchBtn: { type: Boolean, default: true },
    },
    data() {
        return {
            query: "",
            queryDone: false,
            isLoading: false,
            isSearchFocused: false,
            results: [],
            focusedItem: null,
            reactivity,
        };
    },
    inject: ["mq", "emitter"],
    computed: {
        responsiveButton() {
            return (
                this.modifiers.includes("landing") &&
                this.mq.current !== "phone"
            );
        },
        searchValid() {
            return !!(this.query && this.query.length);
        },
        searchActionUrl() {
            return this.reactivity.settings.hasOwnProperty("searchActionUrl")
                ? this.reactivity.settings.searchActionUrl +
                      "?search=" +
                      this.query
                : "";
        },
        hasResults() {
            return !!(this.results && this.results.length);
        },
    },
    methods: {
        getResults() {
            this.isLoading = true;
            this.queryDone = false;

            const appLang =
                document.getElementsByTagName("html")[0].getAttribute("lang") ||
                "en";

            axios
                .get(`/api/search?q=${encodeURI(this.query)}`, {
                    headers: {
                        Language: appLang,
                        "App-Zone":
                            Intl.DateTimeFormat().resolvedOptions().timeZone ||
                            "",
                    },
                })
                .then((response) => {
                    this.results = null;
                    if (response.status == 200) {
                        this.results = response.data.results;
                        this.queryDone = true;
                        this.isLoading = false;
                    }
                });
        },
        clearFld() {
            this.query = "";
            this.results = "";
            this.focusedItem = null;
            this.isSearchFocused = false;
        },
        blurSearch() {
            this.clearFld();
            if (this.$refs.bsearch) this.$refs.bsearch.focus();
        },
        onSearchFocus($event) {
            this.$refs.bsearch.focus();
            this.isSearchFocused = true;
            $event.stopPropagation();
        },
        search: _.debounce(function () {
            if (this.searchValid) this.getResults();
        }, 500),
        submitForm() {
            // TODO: remove and add no-results page
            if (this.reactivity.type && this.reactivity.type == "blog") return;
            if (this.query && this.query.length)
                window.location = this.searchActionUrl;
        },
        arrowUp(item, i) {
            let ref = this.$refs["item-" + (i - 1)];
            if (i - 1) {
                this.$refs.bsearch.focus();
            }
            if (i >= 0 && ref) ref[0].focus();
        },
        arrowDown(item, i) {
            if (this.hasResults) {
                let ref = this.$refs["item-" + (i + 1)];
                if (i < this.results.length && ref) ref[0].focus();
                else this.$refs["item-" + 0][0].focus();
            }
        },
        startSearch(e) {
            if (e.key == "ArrowUp") return;
            if (e.key == "ArrowDown" && this.hasResults) {
                this.$refs["item-" + 0][0].focus();
                return;
            }
            this.search();
        },
        mouseEnter(item, i) {
            let ref = this.$refs["item-" + i];
            if (i < this.results.length && ref) ref[0].focus();
        },
        goToDestination(item, i) {
            let parsedItem = JSON.parse(JSON.stringify(item));
            window.location.href = parsedItem.url;
        },
    },
    watch: {
        searchValid: function (n) {
            if (!n) this.queryDone = false;
        },
        isSearchFocused: function (n) {
            if (n) document.body.classList.add("is-focused");
            if (!n) document.body.classList.remove("is-focused");
        },
    },
    mounted() {
        // miss click
        document.addEventListener(
            "click",
            () => {
                this.clearFld();
            },
            { once: true }
        );

        this.emitter.on("open-haburger-menu-and-focus", () => {
            this.reactivity.hamburger = true;
            this.reactivity.hamburgerMenu = true;
        });

        if (this.mq.current == "phone") this.$refs.bsearch.focus();
        // set query
        let queryText = location.search.split("search=")[1];
        if (!!queryText) {
            this.query = this.query = decodeURI(queryText);
        }
    },
};
</script>
