<template>
    <transition name="hamburger-menu-transition">
        <div
            v-show="reactivity.hamburgerMenu"
            class="hamburger-menu"
            :class="{ 'hamburger-menu--gap-300': faqMenuExists }"
        >
            <div class="column gap--200">
                <!-- Search component -->
                <div
                    v-if="reactivity.type == 'pornsitesall'"
                    class="hamburger-menu__search"
                >
                    <form
                        class="search"
                        :action="reactivity.settings.searchActionUrl"
                        method="GET"
                        autocomplete="off"
                    >
                        <label class="search-input">
                            <div class="search-input__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M16 16L20 20M18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z"
                                        stroke="#191919"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <input
                                autocomplete="off"
                                type="text"
                                name="search"
                                :placeholder="
                                    reactivity.translations
                                        .searchPlaceholderMobile
                                "
                            />
                        </label>
                    </form>
                </div>
                <div v-else class="hamburger-menu__search">
                    <search :show-search-btn="false"></search>
                </div>

                <!-- Horizontal scroller tags -->
                <ul v-if="cremaTags.length" class="topics row gap--100">
                    <li v-if="mq.current !== 'phone'">
                        <span
                            class="button button--small button--transparent-only not-clickable"
                            >{{
                                reactivity.translations.psaTrendingTagsTitle
                            }}</span
                        >
                    </li>
                    <li v-for="(item, i) in cremaTags" :key="'tag-' + i">
                        <a
                            :href="item.url"
                            class="button button--small button--curved"
                        >
                            <img
                                src="@@@/pornsitesall/general/hashtag.svg"
                                alt="hashtag"
                                width="16"
                                height="16"
                            />
                            <span>{{ item.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <!-- faq categories -->
            <ul v-if="faqMenuExists" class="link-nav link-nav--l">
                <li
                    v-for="(item, i) in faqMenu"
                    :key="'cat-' + i"
                    class="link-nav__item"
                >
                    <button @click="onClick(i)" class="link-nav-cat">
                        <img
                            v-if="item.image"
                            :src="item.image.url"
                            :alt="item.name"
                        />
                        <span>{{ item.name }}</span>
                        <svg
                            class="chevron"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.30038 3.28549C7.69499 2.8991 8.32812 2.90576 8.71451 3.30038L16.7145 11.4706C17.0952 11.8593 17.0952 12.4811 16.7145 12.8698L9.04784 20.6996C8.66145 21.0942 8.02832 21.1009 7.63371 20.7145C7.2391 20.3281 7.23243 19.695 7.61882 19.3004L14.6004 12.1702L7.28549 4.69963C6.8991 4.30501 6.90576 3.67188 7.30038 3.28549Z"
                                fill="#0D0D0D"
                            />
                        </svg>
                    </button>

                    <ul
                        class="link-nav-sub"
                        :class="{ opened: opened == i }"
                        :style="[
                            opened == i
                                ? 'max-height:' +
                                  (item.links.length * 36 + 24) +
                                  'px'
                                : 'max-height: 0',
                            opened == i
                                ? 'transition-duration:' +
                                  (item.links.length * 100 + 200) +
                                  'ms'
                                : 'transition-duration: .2s',
                        ]"
                    >
                        <li
                            v-for="(subitem, i) in item.links"
                            :key="'cat-' + i"
                            class="link-nav-sub__item"
                            :class="{ active: getActiveItemUrl(subitem.slug) }"
                        >
                            <a
                                :href="'/' + item.slug + '/' + subitem.slug"
                                class=""
                            >
                                <span>{{ subitem.name }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

            <!-- blog categories -->
            <ul
                v-if="blogMenuExists"
                class="hamburger-menu__nav hamburger-menu__nav--blog"
            >
                <li v-for="(item, i) in blogMenu" :key="'cat-' + i">
                    <a :href="item.url" class="button">
                        <img v-if="item.image" :src="item.image" />
                        <span>{{ item.name }}</span>
                    </a>
                </li>
            </ul>

            <!-- Crema hamburger content -->
            <div v-if="cremaExists" class="hamburger-menu__section">
                <ul
                    v-if="cremaMenu.length"
                    class="hamburger-menu__nav hamburger-menu__nav--crema"
                >
                    <li
                        v-for="(item, i) in cremaMenu"
                        :key="'cat-' + i"
                        class="hamburger-menu__nav--crema__item"
                    >
                        <a
                            :href="item.url"
                            class="button button--curved button--transparent button--justify-start"
                        >
                            <div class="icon" v-html="item.icon"></div>
                            <span>{{ item.name }}</span>
                        </a>
                    </li>
                </ul>

                <a
                    href="mailto:info@pornsitesall.com?subject=Pornsitesall%20linkbuilding"
                    class="button button--brand--primary button--rounded"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M12 8.69458V15.2883M15.3 11.9914H8.7M16.2171 3H7.78286C4.84286 3 3 5.08088 3 8.02664V15.9734C3 18.9191 4.83429 21 7.78286 21H16.2171C19.1657 21 21 18.9191 21 15.9734V8.02664C21 5.08088 19.1657 3 16.2171 3Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <span>Submit Site</span>
                </a>

                <div class="column gap--200 column--center">
                    <a href="/">
                        <img
                            data-theme="dark"
                            src="@@@/pornsitesall/logo-dm.svg"
                            alt="logo dark"
                            width="124"
                        />
                        <img
                            data-theme="light"
                            src="@@@/pornsitesall/logo.svg"
                            alt="logo light"
                            width="124"
                        />
                    </a>
                    <h4
                        class="text--fw-600"
                        v-html="footerData.cremaSlogan"
                    ></h4>
                </div>
            </div>

            <div class="hamburger-menu__actions">
                <theme-switch></theme-switch>
                <lang-dropdown
                    v-if="hideForFV && !cremaExists"
                    :emoji="false"
                    :items="reactivity.langDropdownData"
                    :reserved-space="false"
                >
                </lang-dropdown>
            </div>

            <!-- footer -->
            <div class="hamburger-menu__footer">
                <div class="hamburger-menu__footer__wrapper p p3">
                    <a target="_blank" :href="footerData.instagramLink">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                        >
                            <path
                                d="M10.2441 3.41046V3.45646M4.18811 0.5H9.81127C11.7767 0.5 13 1.88746 13 3.85092V9.14907C13 11.1125 11.7767 12.5 9.8106 12.5H4.18811C2.2227 12.5 1 11.1125 1 9.14907V3.85092C1 1.88746 2.22854 0.5 4.18811 0.5ZM6.89633 4.38411C5.60682 4.38411 4.5612 5.42973 4.5612 6.71927C4.5612 8.00873 5.60682 9.0544 6.89633 9.0544C8.18587 9.0544 9.23147 8.00873 9.23147 6.71927C9.23147 5.42973 8.18587 4.38411 6.89633 4.38411ZM10.4321 3.42047C10.4321 3.52514 10.3474 3.6098 10.2427 3.6098C10.1381 3.6098 10.0527 3.52514 10.0527 3.42047C10.0527 3.31581 10.1381 3.23047 10.2427 3.23047C10.3474 3.23047 10.4321 3.31581 10.4321 3.42047Z"
                                stroke="#8B8B8B"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <span class="text">Instagram</span>
                    </a>
                    <a target="_blank" :href="footerData.twitterLink">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                        >
                            <path
                                d="M9.26864 7.47901L14.2323 1.83325H13.0561L8.74613 6.73538L5.30381 1.83325H1.3335L6.53896 9.24614L1.3335 15.1666H2.50978L7.06117 9.98977L10.6965 15.1666H14.6668L9.26835 7.47901H9.26864ZM7.65755 9.31145L7.13013 8.5733L2.93362 2.69971H4.74033L8.12696 7.43988L8.65439 8.17804L13.0566 14.3395H11.2499L7.65755 9.31174V9.31145Z"
                                fill="#8B8B8B"
                            />
                        </svg>
                        <span class="text">Twitter</span>
                    </a>
                </div>
                <div class="hamburger-menu__footer__wrapper p p3">
                    <a :href="footerData.privacyLink">{{
                        footerData.privacyLinkTranslation
                    }}</a>
                    <a :href="footerData.termsLink">{{
                        footerData.termsLinkTranslation
                    }}</a>
                </div>
                <div class="hamburger-menu__footer__wrapper p p3">
                    {{ footerData.hamburgerCopyright1 }}
                    {{ new Date().getFullYear() }}
                    {{ footerData.hamburgerCopyright2 }}
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { reactivity } from "@/store/reactivity";

export default {
    name: "HamburgerMenu",
    inject: ["mq", "emitter"],
    props: {
        footerData: {
            type: Object,
            default: () => {},
        },
        faqMenu: { type: Array, default: () => [] },
        blogMenu: { type: Array, default: () => [] },
        cremaMenu: { type: Array, default: () => [] },
        cremaTags: { type: Array, default: () => [] },
    },
    data() {
        return {
            opened: null,
            reactivity,
        };
    },
    methods: {
        onClick(i) {
            if (this.opened != i) this.opened = i;
            else this.opened = null;
        },
        getActiveItemUrl(slug) {
            const lastUrlSegment = window.location.pathname.split("/").pop();
            return !!(lastUrlSegment == slug);
        },
    },
    computed: {
        faqMenuExists() {
            return !!(this.faqMenu && this.faqMenu.length);
        },
        blogMenuExists() {
            return !!(this.blogMenu && this.blogMenu.length);
        },
        cremaExists() {
            return (
                !!(this.cremaMenu && this.cremaMenu.length) &&
                !!(this.cremaTags && this.cremaTags.length)
            );
        },
        hideForFV() {
            let domain = window.location.hostname;
            if (domain && domain.split(".")[1] == "freevideo") return false;
            return true;
        },
    },
    watch: {
        reactivity: {
            deep: true,
            handler: function (n) {
                if (this.faqMenu.length) {
                    const url = window.location.pathname.split("/");
                    // order by opened
                    let activeItem = this.faqMenu.splice(this.opened, 1)[0];
                    this.faqMenu.unshift(activeItem);
                    // open by active url
                    this.faqMenu.find((value, index) => {
                        if (value.slug == url[1]) this.opened = index;
                    });
                }
            },
        },
    },
    mounted() {
        this.emitter.on("open-haburger-menu-and-focus", () => {
            this.reactivity.hamburger = true;
            this.reactivity.hamburgerMenu = true;
        });
    },
};
</script>

<style lang="scss" scoped>
.hamburger-menu {
    position: fixed;
    width: 100%;
    background: var(--background-primary);
    top: var(--header-height);
    left: 0;
    bottom: 0;
    z-index: 30;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
    padding: var(--spacing-300);

    &--gap {
        &-300 {
            gap: var(--spacing-300);
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-400);
    }

    &__search {
        min-height: var(--default-height);
        box-sizing: content-box;
    }

    &__nav {
        list-style: none;
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        &--blog {
            gap: var(--spacing-200);

            .button {
                gap: var(--spacing-75);

                & > img {
                    width: 19px;
                }
            }
        }

        &--crema {
            &__item {
                padding: var(--spacing-200) 0;

                &:not(:first-child) {
                    border-top: 1px solid var(--border-primary);
                }

                .icon {
                    display: flex;
                    width: 24px;
                    height: 24px;

                    :deep(svg) {
                        path {
                            stroke: var(--element-icon-primary);
                        }
                    }
                }
            }
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--default-height);

        .dropdown-holder {
            margin-left: auto;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-200);
        background: var(--background-primary);
        padding: var(--spacing-200) 0 0;
        margin-top: auto;
        border-top: 1px solid var(--border-secondary);

        &__wrapper {
            display: flex;
            align-items: center;
            color: var(--text-tertiary);
            text-align: center;
        }

        a {
            display: flex;
            align-items: center;
            color: var(--text-tertiary);
            font-weight: 400;

            &:not(:last-child)::after {
                content: "•";
                flex-shrink: 0;
                margin: 0 var(--spacing-200);
                font-weight: 300;
            }

            .text {
                margin-left: 6px;
            }

            path {
                &[stroke] {
                    stroke: var(--element-icon-secondary);
                }

                &[fill] {
                    fill: var(--element-icon-secondary);
                }
            }
        }
    }

    &-transition {
        &-enter-active,
        &-leave-active {
            transition: transform 0.5s;
        }

        &-enter-from,
        &-leave-to {
            transform: translate3d(-100%, 0, 0);
        }
    }
}
</style>
