<template>
    <div :class="modifyClass" v-show="reactivity.loadMoreButtonList.length">
        <div v-for="(item, i) in reactivity.loadMoreButtonList" :key="i" v-html="item">
        </div>
    </div>
</template>

<script>
import { reactivity } from "@/store/reactivity";

export default {
    name: "LoadMoreButtonList",
    props: {
        modifyClass: { type: String, default: '' },
    },
    data() {
        return {
            reactivity
        };
    }
};
</script>
