<template>
    <transition name="c-slide-down">
        <div
            v-show="showSlideUp"
            class="c-slide-down"
            :class="modifiers.map((m) => 'c-slide-down--' + m)"
            v-scroll-lock="showSlideUp"
        >
            <div class="c-slide-down__overlay" @click="close"></div>
            <div
                class="c-slide-down__content"
                @click.stop=""
                :style="
                    slidingPx
                        ? {
                              transform:
                                  'translate3d(0,' + slidingPx + 'px, 0)',
                          }
                        : {}
                "
                @touchstart="startHandler"
                @touchmove.prevent="movingHandler"
                @touchend="stopHandler"
            >
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
import { reactivity } from "@/store/reactivity";

export default {
    name: "SlideDown",
    props: {
        modifiers: { type: Array, default: () => [] },
    },
    data() {
        return {
            slidingPx: 0,
            startPoint: null,
            currentPoint: 0,
            dragging: false,
            reactivity,
        };
    },
    computed: {
        showSlideUp() {
            return this.reactivity ? this.reactivity.cSlideDown : false;
        },
    },
    methods: {
        close() {
            this.reactivity.cSlideDown = false;
            this.reactivity.cSlideDownSearchBtn = true;
        },
    },
};
</script>

<style scoped lang="scss">
.c-slide-down {
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 60;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__overlay {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(5px);
    }

    &__content {
        width: 100%;
        max-width: 648px;
        display: flex;
        padding: var(--spacing-300);
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-300);
        border-radius: 0 0 24px 24px;
        background: var(--background-primary);
        box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05),
            0 10px 15px -3px rgba(0, 0, 0, 0.1);
        z-index: 60;

        @include breakpoint(t) {
            padding: var(--spacing-400);
            gap: var(--spacing-400);
        }
    }

    &-enter-active,
    &-leave-active {
        .search-slide-down__content {
            transition: transform 0.24s cubic-bezier(0.2, 0, 0.05, 1);
        }

        &::before {
            transition: opacity 0.24s cubic-bezier(0.2, 0, 0.05, 1);
        }
    }

    &-enter-from,
    &-leave-to {
        .search-slide-down__content {
            transform: translate(0, -100%);
        }

        &::before {
            opacity: 0;
        }
    }
}
</style>
